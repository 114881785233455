import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom"
import Forminputgroup from '../../component/reusables/Forminputgroup'
import PasswordInput from '../../component/reusables/PasswordInput'
import { ImSpinner3 } from "react-icons/im";
import { toast } from "react-toastify";
import logo from "../../assets/img/Zealand.png";
import { HiArrowNarrowRight } from "react-icons/hi";
import { login, reset } from "../../features/auth/authSlice";
import loginImg from "../../assets/img/login-concept-illustration_114360-739.avif";

const Signin = () => {
    const [userData, setUserData] = useState({
        email: "",
        password: "",
      });
      const [toggle, setToggle] = useState(false)
    

      const { email, password } = userData;
  const handleToggele = () =>{
    setToggle(!toggle)
  }

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const { user, isError, isSuccess, isLoading, message } = useSelector(
    (state) => state.auth
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    // dispaly toast message when error
    if (isError) {
      toast.error(message);
    }
    //redirect to dashboard, when successful
    if (isSuccess) {
      toast.success("Login successfully!");
      navigate('/admin/dashboard')
    }
    dispatch(reset());
  }, [isError, isSuccess, message, user, navigate, dispatch]);

  const onSubmit = (e) =>{
    e.preventDefault();
   if(password === ""){
    toast.error("Password is required")
   } else if (email === ""){
    toast.error("Email is required")
   }else {
    dispatch(login(userData))
   }
  }
  
  return (
    <section className="section-sm ">
      
    <div className="container px-4 new-style ">
  
      <Link className="navbar-brand logo-wraps" to={`/`}>
                <img className="" src={logo} alt="zealand logo" />
              </Link>
      <div className="row page-wrapper bg-light-2">
              <div className="col-lg-6 left"> 
                  <img src={loginImg} alt="" />
               </div>
               
              <div className="col-lg-6 right">
                  <h4 className="real-title">Admin Login</h4>  
                  <p className="more-details">Please provide a valid Administration creential</p>

                  <Forminputgroup
                      inputLabel={`Email Address`}
                      inputName="email"
                      inputType={`email`}
                      inputRequire={true}
                      value={userData.email}
                      onChange={handleChange}
                />

            <PasswordInput
              inputLabel={`Password`}
              inputType={toggle ? `text` : `password`}
              inputName="password"
              inputRequire={true}
              value={userData.password}
              onChange={handleChange}
              toggle={toggle}
              handleToggele ={handleToggele}
              size="col-lg-12"
            />

        
        <button  className="btn btn-success" onClick={onSubmit}>
              
              <span> Sign in</span>
               
               {isLoading ? <ImSpinner3 className="icon-spin" /> : <HiArrowNarrowRight />}
             </button>
      


          <div className="form-footer">
                  <p className="txt-center">
                    <Link
                      className="forget-pass"
                      to={`/forgot-password`}
                    >
                      {" "}
                      Forgot your password?
                    </Link>
                  </p>
                 
                </div>

              </div> 
      </div>
    </div>
  </section>
  )
}

export default Signin