export const genderList = [
    {
        id:1,
        value:'male',
        caption:'Male'
    },
    {
        id:2,
        value:'female',
        caption:'Female'
    }
]