import React, {useState} from 'react'
import Sidebar from '../admincomponent/Sidebar'
import UserTopBar from '../../component/AppTopBar'
import Forminputgroup from '../../component/reusables/Forminputgroup'

const AdminPasswordChange = () => {

  const initialData  = {
    current_password: '',
    new_password:  '',
    new_password_confirmation: '',

}
const [profileData, setProfileData] = useState(initialData)

// const { isLoading, isSuccess, message, profile, isError} = useSelector((state) => state.adminProfile)
// const dispatch = useDispatch()


const handlechange = (e) =>{
    setProfileData({...profileData, [e.target.name]:e.target.value})
}

const onSubmit = (e) =>{
    e.preventDefault();
   
    // dispatch(resetPassword(profileData));
}

  return (
    <div className="inner-page-wrapper">
    <Sidebar />
    <div id="content-wrapper" className="content-wrapper d-flex flex-column">
      {/* Main Content  */}
      <div id="content">
        {/* Topbar */}

        <UserTopBar />

        {/* Begin Page Content  */}
        <div className="container-fluid">
          {/* Page Heading  */}
          <div className="d-sm-flex align-items-center justify-content-between mb-5">
            <div className="page-breadcrumbs">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center text-center">
                  <li className="breadcrumb-item ">Dashboard</li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Reset Password
                  </li>
                </ol>
              </nav>
            </div>
          </div>

                <div className="row">
                   
                    <div className="col-lg-8">
                    <form autoComplete="off" onSubmit={onSubmit}>
                <div className="form-header text-left">
                  <h5 className="form-title">
                    Reset Password 
                  </h5>
                </div>
                <div className="form-body">
                  

                  <div className="form-group row">
                    <Forminputgroup
                      inputLabel={`Current Password`}
                      inputName="current_password"
                      inputType={`text`}
                      inputRequired={`required`}
                      onChange={handlechange}
                      value={profileData.current_password}
                     
                    />
                    <Forminputgroup
                      inputLabel={`New Password`}
                      inputName="new_password"
                      inputType={`text`}
                      inputRequired={`required`}
                      onChange={handlechange}
                      value={profileData.new_password}
                     
                    />
                  </div>

                  <div className="form-group row">
                    <Forminputgroup
                      inputLabel={`Confirm Password`}
                      inputName="new_password_confirmation"
                      inputType={`text`}
                      inputRequired={`required`}
                      onChange={handlechange}
                      value={profileData.new_password_confirmation}
                     
                    />
                    
                  </div>
                


                  <div className="form-group btn-wrap form-btn">
                    <button className="btn btn-lingt btn-shadow btn-round btn-md">
                    <i className="bi bi-save2"></i> Update Password
                    </button>
                  </div>
                </div>
              </form>
                    </div>
                </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default AdminPasswordChange