import axios from "axios";


const baseUrl = process.env.REACT_APP_BASE_URL
const apiVersion = process.env.REACT_APP_API_VERSION

const apiUrl = `${baseUrl}/${apiVersion}`


// ********Auth****************//

export const register = async (userData) =>{
  return axios.post(`${apiUrl}/users`, userData)
}

//update profile
export const updateUser = async (userData, userId) =>{
  return axios.put(`${apiUrl}/users/${userId}`, userData)
}


//login
export const userLogin = async (userData) =>{
  return axios.post(`${apiUrl}/auth/login`, userData)
}

//logout 

export const userLogout = async (token) =>{

  const config = {
      headers :{
        Authorization: `Bearer ${token}`
      }
    }
    const response = await axios.post(`${apiUrl}/auth/logout`, {}, config);
  
    if(response.status === 200){
      localStorage.removeItem("user")
    
  }
  return response.data
  
}

// ************BANK*****************
// Get bank by user id
export const getUserBank = (userId) => {
  return  axios.get(`${apiUrl}/bank/user?userId=${userId}`);
 };

//  Add bank to user profile
export const addBank = async (bankData) =>{
  return axios.post(`${apiUrl}/banks`, bankData)
}


// **************USER****************
export const getUserprofile = (userId) => {
  return  axios.get(`${apiUrl}/users/${userId}?include=true`);
 };




  //Fetch the lisy of countries  /bank/user?userId=3
export const getProfile = (id) => {
   return  axios.get(`${apiUrl}/users/${id}`);
  };

  export const saveBuyer = (data) =>{
    return axios.post(`${apiUrl}/buyers`, data)
}

export const getBuyers = () =>{
  return axios.get(`${apiUrl}/buyers`)
}

export const getBuyer = (id) =>{
  return axios.get(`${apiUrl}/buyers/${id}`)
}

export const getUser = (id) =>{
  return axios.get(`${apiUrl}/users/${id}?include=true`)
}

// Get list of registered users
export const  getAllUsers = (page) =>{
  return  axios.get(`${apiUrl}/users?page=${page}&include=true`);
}
//  const { data } = await axios.get(`${apiUrl}/users?page=${page}`, config);

// Get all properties
export const  getAllProperties = () =>{
  return  axios.get(`${apiUrl}/properties`);
}

// Create property
export const addProperty = async (data) =>{
  return axios.post(`${apiUrl}/properties`, data)
}

// get all clients
export const  getSingleClient = (id) =>{
  return  axios.get(`${apiUrl}/clients/${id}`);
}