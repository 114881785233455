import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import service from './clientService'


const initialState = {
    client: null,
    clients: null,
    resp: null,
    isErrorC: false,
    isSuccessC:false,
    isFetchSuccessC: false,
    isDeleteC:false,
    isDeletingC:false,
    isLoadingC:false,
    messageC:''
}

//@function: create director record
export const createClient = createAsyncThunk('client/create', async (inputData, thunkAPI) =>{
  
    try {
        const { token } = JSON.parse(localStorage.getItem("user"));
        return await service.createClient(inputData, token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

//@function: create individual record
export const getClients = createAsyncThunk('clients/fetch', async (_,thunkAPI) =>{
    try {
        const { token } = JSON.parse(localStorage.getItem("user"));
        return await service.getClients(token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

//@function: create individual record
export const getClient = createAsyncThunk('client/fetch', async (id, thunkAPI) =>{
    try {
        const { token } = JSON.parse(localStorage.getItem("user"));
        return await service.getClient(id, token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

//@function: delete property
export const deleteClient = createAsyncThunk('client/delete', async (id, thunkAPI) =>{
    
    try {
        const { token } = JSON.parse(localStorage.getItem("user"));
        return await service.deleteClient(id,token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

//@function: delete bussiness
export const updateClient = createAsyncThunk('client/update', async (id, inputData, thunkAPI) =>{
    
    try {
        const { token } = JSON.parse(localStorage.getItem("user"));
        return await service.updateClient(id,inputData, token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

export const clientSlice = createSlice({
    name:'client',
    initialState,
    reducers:{
        reset:(state) => {
            state.isErrorC = false
            state.isLoadingC = false
            state.isSuccessC = false
            state.isFetchSuccessC = false
            state.isDeleteC = false
            state.isDeletingC = false
            state.messageC = ""
            state.client = null
            state.clients = null
            state.resp = null
        }
    },
    extraReducers: (builder) =>{
        builder
            .addCase(createClient.pending, (state)=>{
                state.isLoadingC = true
            })
            .addCase(createClient.fulfilled, (state, action)=>{
                state.isLoadingC = false
                state.isSuccessC = true
                state.resp = action.payload
            })
            .addCase(createClient.rejected, (state, action) =>{
                state.isErrorC = true
                state.isLoadingC = false
                state.messageC= action.payload
              
            })
            .addCase(getClients.pending, (state)=>{
                state.isLoadingC = true
            })
            .addCase(getClients.fulfilled, (state, action)=>{
                state.isLoadingC = false
                state.isFetchSuccessC = true
                state.clients = action.payload
            })
            .addCase(getClients.rejected, (state, action) =>{
                state.isErrorC = true
                state.isLoadingC = false
                state.messageC = action.payload
              
            })
            .addCase(deleteClient.pending, (state)=>{
                state.isDeletingC = true
            })
            .addCase(deleteClient.fulfilled, (state, action)=>{
                state.isDeletingC = false
                state.isDeleteC = true
            })
            .addCase(deleteClient.rejected, (state, action) =>{
                state.isErrorC = true
                state.isDeletingC = false
                state.messageC = action.payload
            })
            .addCase(getClient.pending, (state)=>{
                state.isLoadingC = true
            })
            .addCase(getClient.fulfilled, (state, action)=>{
                state.isLoadingC = false
                state.isFetchSuccessC = true
                state.client = action.payload
            })
            .addCase(getClient.rejected, (state, action) =>{
                state.isErrorC = true
                state.isLoadingC = false
                state.messageC = action.payload
              
            })
            .addCase(updateClient.pending, (state)=>{
                state.isLoadingC = true
            })
            .addCase(updateClient.fulfilled, (state, action)=>{
                state.isLoadingC = false
                state.isFetchSuccessC = true
                state.client = action.payload
            })
            .addCase(updateClient.rejected, (state, action) =>{
                state.isErrorC = true
                state.isLoadingC = false
                state.messageC = action.payload
              
            })
    }
})

export const { reset} = clientSlice.actions
export default clientSlice.reducer