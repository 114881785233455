import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate,  useParams, Link } from "react-router-dom";
import loginImg from "../../assets/img/register-image.avif";
import { toast } from "react-toastify";
import { ImSpinner3 } from "react-icons/im";

import Forminputshort from "../../component/reusables/Forminputshort"
import Forminputgroup from "../../component/reusables/Forminputgroup"


import PasswordInput from "../../component/reusables/PasswordInput";
import { HiArrowNarrowRight } from "react-icons/hi";
import { register } from "../../util/services";
import logo from "../../assets/img/Zealand.png";

const Register = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { refCode} = useParams()


 
  const [toggle, setToggle] = useState(false)

  
  const [loading, setLoading] = useState(false)
  

  const initialValues = {
    firstName:'',
    lastName:'',
    email:'',
    phoneNumber:'',
    password:'',
    address:'',
    dob:'',
    gender:'',
    password_confirmation:'',
    referedBy: refCode !== undefined  ? refCode : 'admin'

  }

  const [userData, setUserdata] = useState(initialValues)
  
  
const {firstName,lastName,email,phoneNumber,password,password_confirmation} = userData;
 

  const handleToggele = () =>{
    setToggle(!toggle)
  }

  const handleChange = (e) =>{
    setUserdata({...userData, [e.target.name]:e.target.value})
  }

 

  const handleSubmit = async (e) =>{
    e.preventDefault()
      if(password !== password_confirmation){
        toast.error("Password do not match")
      }else if(firstName === "" || lastName === "" || email === "" || phoneNumber === "" ) {
        toast.error("Required field was left unfilled")
      }
    else{
    
       try {
        console.log(userData);
        setLoading(true)
         const { data } = await register(userData)
       
         if(data.success){
          localStorage.setItem('user', JSON.stringify(data.data))
          toast.success(data.message)
          navigate('/user/dashboard');
         }
         setLoading(false)
       } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        toast.error(message)
        setLoading(false)
       }

    }
  }

  
 


 
  return (
    <section className="section-sm bg-light-2">
    <div className="container px-4 new-style">
    <Link className="navbar-brand logo-wraps" to={`/`}>
                <img className="" src={logo} alt="zealand logo" />
              </Link>
      <div className="row page-wrapper bg-light-2">
      <div className="col-lg-6 left"> 
                  <img src={loginImg} alt="" />
               </div>
               <div className="col-lg-6 right">
                  <h4 className="real-title">Create your account</h4>  
                  <p className="more-details">Make a passive income with our affiliate system, it's simple and with big reward.</p>

                  <div className="form-group row">
             
             <Forminputshort
               inputLabel={`First Name`}
               inputName="firstName"
               inputType={`text`}
               inputRequired={true}
               value={userData.firstName}
               onChange={handleChange}
             />
             <Forminputshort
               inputLabel={`Last Name`}
               inputName="lastName"
               inputType={`text`}
               inputRequired={true}
               value={userData.lastName}
               onChange={handleChange}
             />
           </div>
           <Forminputgroup
              inputLabel={`Email Address`}
              inputName="email"
              inputType={`email`}
              inputRequire={true}
              value={userData.email}
              onChange={handleChange}
            />

            <Forminputgroup
              inputLabel={`Phone Number`}
              inputName="phoneNumber"
              inputType={`text`}
              inputRequire={true}
              value={userData.phoneNumber}
              onChange={handleChange}
            />

<Forminputgroup
              inputLabel={`Referral Code (Optional)`}
              inputName="referedBy"
              inputType={`text`}
              inputRequire={false}
              value={userData.referedBy}
              onChange={handleChange}
              disabled={true}
              
            />

<PasswordInput
              inputLabel={`Password`}
              inputType={toggle ? `text` : `password`}
              inputName="password"
              inputRequire={true}
              value={password}
              onChange={handleChange}
              toggle={toggle}
              handleToggele ={handleToggele}
              size="col-lg-12"
            />
            
            <PasswordInput
              inputLabel={`Confirm Password`}
              inputType={toggle ? `text` : `password`}
              inputName="password_confirmation"
              inputRequire={true}
              value={password_confirmation}
              onChange={handleChange}
              toggle={toggle}
              handleToggele ={handleToggele}
              size="col-lg-12"
            />
     


     <p className="terms-of-use">By clicking on "sign up" you agree to our Terms of User and Privacy Policy</p>
          
              <button  className="btn btn-success" onClick={handleSubmit}>
              
               <span> Sign up</span>
                
                {loading ? <ImSpinner3 className="icon-spin" /> : <HiArrowNarrowRight />}
              </button>
           
              <div className="form-footer">
                 
                  <p className="txt-center">
                    Already have an account?{" "}
                    <Link className="forget-pass" to={`/`}>
                      {" "}
                      Login{" "}
                    </Link>{" "}
                    here.
                  </p>
                </div>
              </div>
        



      </div>
    </div>
  </section>
  )
}

export default Register