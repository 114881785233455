export const getSubstring = (str) =>{
    if(str !== "") {
        return str.substring(0, 120) + '....'
    }
}

//get percentage

export const getPercentage = (amount, percent) =>{
    if (amount !== null && percent !== null) {
        return ((Number(percent)/ 100) * Number(amount) )
    }
}

export const imgList = (img) =>{
    if (img !== "") {
      return JSON.parse(img)
    }
  }

