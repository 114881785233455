import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import service from './propertyService'


const initialState = {
    property: null,
    properties:null,
    isErrorP: false,
    isSuccessP:false,
    added:false,
    isFetchSuccessP: false,
    isDeleteP:false,
    isDeletingP:false,
    isLoadingP:false,
    messageP:''
}

//@function: create director record
export const createProperty = createAsyncThunk('property/create', async (inputData, thunkAPI) =>{
  
    try {
        const { token } = JSON.parse(localStorage.getItem("user"));
        return await service.createProperty(inputData )  //data.token
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

//@function: create individual record
export const getProperties = createAsyncThunk('properties/fetch', async (_,thunkAPI) =>{
    try {
        
        const { token } = JSON.parse(localStorage.getItem("user"));
        return await service.getProperties(token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

//@function: create individual record
export const getProperty = createAsyncThunk('property/fetch', async (id, thunkAPI) =>{
    try {
        const { token } = JSON.parse(localStorage.getItem("user"));
        return await service.getProperty(id, token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

//@function: delete property
export const deleteProperty = createAsyncThunk('property/delete', async (id, thunkAPI) =>{
    
    try {
        const { token } = JSON.parse(localStorage.getItem("user"));
        return await service.deleteProperty(id, token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

//@function: delete bussiness
export const updateProperty = createAsyncThunk('property/update', async (id, inputData, thunkAPI) =>{
    
    try {
        const { token } = JSON.parse(localStorage.getItem("user"));
        return await service.updateProperty(id,inputData, token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

export const propertySlice = createSlice({
    name:'property',
    initialState,
    reducers:{
        reset:(state) => {
            state.isErrorP = false
            state.isLoadingP = false
            state.isSuccessP = false
            state.isFetchSuccessP = false
            state.isDeleteP = false
            state.isDeletingP = false
            state.messageP = ""
            state.property = null
            state.properties = null
            state.added = false
        }
    },
    extraReducers: (builder) =>{
        builder
            .addCase(createProperty.pending, (state)=>{
                state.isLoadingP = true
            })
            .addCase(createProperty.fulfilled, (state, action)=>{
                state.isLoadingP = false
                state.added = true
                state.isSuccessP = true
                state.property = action.payload
            })
            .addCase(createProperty.rejected, (state, action) =>{
                state.isErrorP = true
                state.isLoadingP = false
                state.added = false
                state.messageP = action.payload
              
            })
            .addCase(getProperties.pending, (state)=>{
                state.isLoadingP = true
            })
            .addCase(getProperties.fulfilled, (state, action)=>{
                state.isLoadingP = false
                state.isFetchSuccessP = true
                state.properties = action.payload
            })
            .addCase(getProperties.rejected, (state, action) =>{
                state.isErrorP = true
                state.isLoadingP = false
                state.messageP = action.payload
              
            })
            .addCase(deleteProperty.pending, (state)=>{
                state.isDeletingP = true
            })
            .addCase(deleteProperty.fulfilled, (state, action)=>{
                state.isDeletingP = false
                state.isDeleteP = true
            })
            .addCase(deleteProperty.rejected, (state, action) =>{
                state.isErrorP = true
                state.isDeletingP = false
                state.messageP = action.payload
            })
            .addCase(getProperty.pending, (state)=>{
                state.isLoadingP = true
            })
            .addCase(getProperty.fulfilled, (state, action)=>{
                state.isLoadingP = false
                state.isFetchSuccessP = true
                state.property = action.payload
            })
            .addCase(getProperty.rejected, (state, action) =>{
                state.isErrorP = true
                state.isLoadingP = false
                state.messageP = action.payload
              
            })
            .addCase(updateProperty.pending, (state)=>{
                state.isLoadingP = true
            })
            .addCase(updateProperty.fulfilled, (state, action)=>{
                state.isLoadingP = false
                state.isFetchSuccessP = true
                state.property = action.payload
            })
            .addCase(updateProperty.rejected, (state, action) =>{
                state.isErrorP = true
                state.isLoadingP = false
                state.messageP = action.payload
              
            })
    }
})

export const { reset} = propertySlice.actions
export default propertySlice.reducer