import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { NumericFormat } from 'react-number-format';
import dateFormat from 'dateformat';
import Sidebar from './Sidebar'
import { toast } from "react-toastify";
import { ImSpinner3 } from "react-icons/im";
import {Modal, Button, Form} from 'react-bootstrap'
import AppTopBar from '../../component/AppTopBar'
import { FaEye, FaTrashAlt, FaPlusCircle, FaTimesCircle, FaRegSave, FaTimes } from 'react-icons/fa'

import Forminputshort from '../../component/reusables/Forminputshort';
import FormSelectComponent from '../../component/reusables/FormSelectComponent'
import { createClient, deleteClient, getClient, getClients} from '../../features/client/clientSlice'
import { genderList } from '../../util/data';
import { getAllProperties, getSingleClient } from '../../util/services';
import SelectProperty from '../../component/reusables/SelectProperty';

const ClientContent = () => {
    const [isLoading, setIsloading] = useState(false)
    const [list, setList] = useState([])
    const [show, setShow] = useState(false)
    const [clientData, setClientdata] = useState({
        clientName:'',
        phoneNumber:'',
        email:'',
        gender:'',
        address:'',
        property:''
    })
    const [clientList, setClientlist] = useState([])
    const [showMore, setShowmore] = useState(false)
    const [sClient, setSClient] = useState(null)

    const { isLoadingC, isErrorC, isFetchSuccessC,isSuccessC, client, clients,resp, messageC } =
useSelector((state) => state.client);

    const handleClose = () => setShow(false)
    const handleChange = (e) =>{
        setClientdata({...clientData, [e.target.name]:e.target.value})
    }

    const dispatch = useDispatch()

    const closeMore = () => setShowmore(false)

useEffect(() => {
  if (isErrorC) {
    toast.error(messageC);
  }
  dispatch(getClients());
  if (isFetchSuccessC) {
    setClientlist(clients.data);
  }
  if(isSuccessC){
    toast.success("Client Addedd Successfully")
    setClientlist([resp.data,...clientList])
  }
  fetchProperty()
}, [isFetchSuccessC, clientList]);

const handleAddClient = (e) =>{
    e.preventDefault()
    
    if(clientData.clientName === "" || clientData.clientPhone === "" || clientData.clientMail === "") {
        toast.error("Required fields is left empty")
    } else{
        dispatch(createClient(clientData))
        toast.success("Client Added!")
    }
}

const fetchProperty = async() =>{
	try {
		const { data } = await getAllProperties()
		setList(data.data)

	} catch (error) {
		const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
		toast.error(message)
	}
}

const handleDelete = (id) =>{
    if(window.confirm("Do you want to delete this client?")){
      if(dispatch(deleteClient(id))){
    toast.success("Client deleted successfully")
    }
      const newData = clients.data.filter((item) => item.id !== id)
      setClientlist( newData)
      window.location.reload()
    }
      
  }

  console.log('Client: ', sClient);
 
  const showClient = (id) =>{
  dispatch(getClient(id))
  }

  const fetchClient = async(id) =>{
    try {
      // setLoading(true)
      const { data } = await getSingleClient(id)
      console.log(data);
      // setUserlist(data.data)
      // setLoading(false)
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
      toast.error(message)
    }
  }

  const renderData = () => {
    if (clientList && clientList.length > 0) {
      return clientList.map((item) => {
        return (
          <tr key={item.id}>
            <td>{item.client_name}</td>
            <td>{item.phone_number}</td>
            <td>{item.email}</td>
            <td>{item.address}</td>
            <td>{item.gender}</td>
            <td>{item.property}</td>
            <td style={{width:'80px'}}>
                <FaTrashAlt className="delete" onClick={() => handleDelete(item.id)}  />{" "}
                <FaEye className="details" onClick={() =>{
                        fetchClient(item.id)
                        setShowmore(true)
                    }}/>
                         
            </td>
              
          </tr>
        );
      });
    } else {
      <div className="alert alert-primary"> No item found</div>;
    }
  };


  return (
    <>
    <div className="inner-page-wrapper">
			
				{/* Sidebar */}
                <Sidebar />
			<div id="content-wrapper" className="content-wrapper d-flex flex-column">

				{/* Main Content  */}
				<div id="content">

					 {/* Topbar */}

				<AppTopBar />

					 {/* Begin Page Content  */}
					<div className="container-fluid">

						{/* Page Heading  */}
						<div className="d-sm-flex align-items-center justify-content-between mb-5">
							
						<div className="page-breadcrumbs">
							<nav aria-label="breadcrumb">
							<ol className="breadcrumb justify-content-center text-center">
								<li className="breadcrumb-item ">Dashboard</li>
								<li className="breadcrumb-item active" aria-current="page">
								 Clients
								</li>
							</ol>
							</nav>
              			</div>
						</div>

						
        <div className="row container mt-4 main-wrap">
        <div className="card shadow mb-5">
				

                <div className="card-header py-3 top">
                <h6 className="m-0 font-weight-bold text-primary">Clients</h6>
                <div> <button className="btn btn-primary" onClick={() => setShow(true)}> <FaPlusCircle /> Add Client </button></div>
              </div>

{
    isLoadingC ? <><ImSpinner3 /> Loading ....</> : 
    <>
    <div className="card-body table-responsive">
			<table className='table table-stripped table-bordered dataTable custom-table'>
				<thead>
					<tr>
                  
						<th>Client Name</th>
						<th>Phone Number</th>
						<th>Email Address</th>
						<th>Address</th>
            <th>Gender</th>
            <th>Property Bought</th>           
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
				{renderData()}

				</tbody>
			</table>
            </div>
    </>
}
				
            </div>
	
        </div>
					
	</div>
	</div>
	</div>
</div>


<Modal show={show} onHide={handleClose} size="lg">
<Modal.Header closeButton>
    <Modal.Title><FaPlusCircle /> Add Client</Modal.Title>
</Modal.Header>
<Modal.Body>
        <div className="form-group row">
        <Forminputshort
            inputLabel={`Client Name`}
            inputName="clientName"
            inputType={`text`}
            inputRequired={true}
            value={clientData.clientName}
            onChange={handleChange}
        />
         <Forminputshort
            inputLabel={` Phone Number`}
            inputName="phoneNumber"
            inputType={`text`}
            inputRequired={true}
            value={clientData.phoneNumber}
            onChange={handleChange}
        />
        </div>

        <div className="form-group row">
        <Forminputshort
            inputLabel={`Email Address`}
            inputName="email"
            inputType={`email`}
            inputRequired={true}
            value={clientData.email}
            onChange={handleChange}
        />
       
            <FormSelectComponent
                      selectName="gender"
                      selectLabel={`Gender`}
                      selectOption ={`--Select Gender--`}
                      itemList={genderList}
                      onChange={handleChange}
                      value={clientData.gender}
                      // defValue={individualData.gender}
                      
                    />

        </div>

        <div className="form-group row">
       
       
            <SelectProperty
                      selectName="property"
                      selectLabel={`Property Bought`}
                      selectOption ={`--Select Property--`}
                      itemList={list}
                      onChange={handleChange}
                      value={clientData.property}
                     
                      
                    />

<div className="form-group input-wrapper">
          <textarea 
                name="address" 
                value={clientData.address} 
                cols="10" rows="2" className='form-control' 
                placeholder="Home Address"
                onChange={handleChange}
                >

          </textarea>
        </div>

        </div>


      
        
       

        
        
        
               
</Modal.Body>
<Modal.Footer>

<Button variant="secondary" onClick={handleAddClient}>
      {
        isLoadingC ? <><ImSpinner3 className="icon-spins" /></>: <><FaRegSave /> Save</>
      }
       
    </Button>

    <Button variant="primary" onClick={handleClose}>
      {
        isLoading ? <><ImSpinner3 className="icon-spins" /></>: <><FaTimes /> Close</>
      }
       
    </Button>

</Modal.Footer>
</Modal>


{/* client details */}
<Modal show={showMore} onHide={closeMore} size="lg">
<Modal.Header closeButton>
    <Modal.Title><FaPlusCircle /> Client Details</Modal.Title>
</Modal.Header>
<Modal.Body>
 
	{
        isLoadingC ? <>Loading...</> :
        <>
        <div className="form-group row">
		<div className="col-lg-6">
			<strong>Client Name: </strong> 
      {client !== null && client.client !== null ? client.client.client_name : ""}
		</div>
		<div className="col-lg-6">
			<strong>Gender: </strong> 
      {client !== null && client.client !== null ? client.client.gender : ""}
		</div>
	</div>

    <div className="form-group description">
  <strong>Address: </strong> 
  {client !== null && client.client !== null ? client.client.address : ""}
  </div>

	<div className="form-group row mt-1">
		<div className="col-lg-6">
			<strong>Amount Paid: </strong> &#x20A6; 
      {client !== null && client.client !== null ? <NumericFormat className="format-number" value={client.client.amount_paid} thousandSeparator={true} /> : ""}
		</div>
		<div className="col-lg-6">
			<strong>Date Purchase: </strong>  
      {client !== null && client.client !== null ? dateFormat( client.client.purchase_date, "dddd, mmmm dS, yyyy, h:MM:ss TT") : ""}
		</div>
	</div>
        </>
    }



        
</Modal.Body>
<Modal.Footer>



    <Button variant="primary" onClick={closeMore}>
      <><FaTimes /> Close</>
    </Button>

</Modal.Footer>
</Modal>

</>
  )
}

export default ClientContent