import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Clients from "./admin/pages/Clients";
import Commision from "./admin/pages/Commision";
import ForgetPassword from "./admin/auth/ForgetPassword";
import ForgotPasswords from "./admin/auth/ForgetPassword";
import Signin from "./admin/auth/Signin";
import AdminDashboard from "./admin/pages/AdminDashboard";
import Users from "./admin/pages/Users";
import ForgotPassword from "./user/auth/ForgotPassword";
import Login from "./user/auth/Login";
import Register from "./user/auth/Register";
import ResetPassword from "./user/auth/ResetPassword";
import ChangePassword from "./user/pages/ChangePassword";
import Dashboard from "./user/pages/Dashboard";
import Profile from "./user/pages/Profile";
import Properties from "./user/pages/Properties";
import Propertys from "./admin/pages/Propertys";
import AdminProfile from "./admin/pages/AdminProfile";
import AdminPasswordChange from "./admin/pages/AdminPasswordChange";
import Referals from "./user/pages/Referals";

import Bank from "./user/pages/Bank";


function App() {
  return (
    <div className="App">
      <Router>
          <Routes>
            <Route path="/">
                  <Route index element={<Login />} />
                  <Route path="login" element={<Login />} />
                  <Route path='register' element={<Register />} />
                  <Route path='register/:refCode' element={<Register />} />
                  <Route path='forgot-password' element={<ForgotPassword />} />
                  <Route path='reset-password' element={<ResetPassword />} />
           

              <Route path="user">
                  <Route path="dashboard" element={<Dashboard />} />
                  <Route path="profile" element={<Profile />} />
                  <Route path="bank" element={<Bank />} />
                  <Route path="referals" element={<Referals />} />
                  <Route path="properties" element={<Properties />} />
                  <Route path="reset-password" element={<ChangePassword />} />
              </Route>
              <Route path="admin">
                  <Route index element={<Signin />} />
                  <Route path='forget-password' element={<ForgetPassword />} />
                  <Route path='dashboard' element={<AdminDashboard />} />
                  <Route path='users' element={<Users />} />
                  <Route path='properties' element={<Propertys />} />
                  <Route path='clients' element={<Clients />} />
                  <Route path='commision' element={<Commision />} />
                  <Route path="profile" element={<AdminProfile />} />
                  {/* <Route path="profile-update" element={<UpdateProfile />} /> */}
                  <Route path="reset-password" element={<AdminPasswordChange />} />
                  
                  

              </Route>

              
          

            
            </Route>
          </Routes>
  </Router> 
  <ToastContainer />

    </div>
  );
}

export default App;
