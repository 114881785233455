import React from 'react'
import Footer from '../../component/Footer'
import UserContent from '../admincomponent/UserContent'

const Users = () => {
  return (
    <>
        <UserContent />
        <Footer />
    </>
  )
}

export default Users