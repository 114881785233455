import {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { FaTimes, FaPlusCircle} from 'react-icons/fa'
import {Modal, Button} from 'react-bootstrap'
import dateFormat from 'dateformat'
import { NumericFormat } from 'react-number-format'
import { FiMoreVertical} from 'react-icons/fi'
import Sidebar from '../usercomponent/Sidebar'
import UserTopBar from '../../component/UserTopbar'
import { toast } from 'react-toastify'
import { getProperties, getProperty, reset } from "../../features/property/propertySlice";
import defaultPropertyImg from "../../assets/img/estate1.jpg"

import { imgList } from '../../util/helpers'

const Properties = () => {
const [propertyData, setPropertydata] = useState([])
const [showMore, setShowmore] = useState(false)

const { isLoadingP, isErrorP, isFetchSuccessP, properties,property, messageP } =
useSelector((state) => state.property);

const dispatch = useDispatch()
const closeMore = () => setShowmore(false)
useEffect(() => {
  if (isErrorP) {
    toast.error(messageP);
  }
  dispatch(getProperties());
  if (isFetchSuccessP) {
    setPropertydata(properties.data);
  }
}, [isFetchSuccessP, propertyData]);

const showProperty = (id) =>{
  dispatch(getProperty(id))
  }

  const renderData = () => {
    if (propertyData && propertyData.length > 0) {
      return propertyData.map((item) => {
        return (
          <tr key={item.id}>
            <td style={{width:'20%'}}>{item.property_name}</td>
            <td style={{width:'20%'}} >{item.location}</td>
            <td><NumericFormat prefix='NGN' value={item.actual_price} thousandSeparator={true} className="format-number" /></td>
            <td><NumericFormat prefix='NGN' value={item.promo_price} thousandSeparator={true} className="format-number" /></td>
            <td><NumericFormat prefix='NGN' value={item.survey_price} thousandSeparator={true} className="format-number" /></td>   
            <td><NumericFormat prefix='NGN' value={item.developmental_levy} thousandSeparator={true} className="format-number" /></td> 
            <td>{item.c_of_o}</td>  
            <td>{item.deed_of_assignment}</td> 
            <td style={{width:'150px'}}>
              <button className='customeBtn custom-secondary' onClick={() =>{
                showProperty(item.id)
                setShowmore(true)
              }}><FiMoreVertical /> Details
              </button></td> 
          </tr>
        );
      });
    } else {
      <div className="alert alert-primary"> No item found</div>;
    }
  };

  return (
    <>
    <div className="inner-page-wrapper">
			
      {/* Sidebar */}
              <Sidebar />
    <div id="content-wrapper" className="content-wrapper d-flex flex-column">

      {/* Main Content  */}
      <div id="content">

         {/* Topbar */}

      <UserTopBar />

         {/* Begin Page Content  */}
        <div className="container-fluid">

          {/* Page Heading  */}
          <div className="d-sm-flex align-items-center justify-content-between mb-5">
            
          <div className="page-breadcrumbs">
            <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-center text-center">
              <li className="breadcrumb-item ">Dashboard</li>
              <li className="breadcrumb-item active" aria-current="page">
               Properties
              </li>
            </ol>
            </nav>
            </div>
          </div>
          
          <div className="card shadow mb-4">
                <div className="card-header py-3 top">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Properties
                  </h6>
                  <div>
                    {" "}
                    
                  </div>
                </div>

                { isLoadingP ? <> Loading....</> : 
                <>
                <div className="card-body">
                  <div className="table-responsive">
                    <div className="data-table">
                      <div className="row">
                        <div className="col-sm-12">
                          <table
                            className="table table-bordered dataTable custom-table"
                            id="mainTable"
                            width="100%"
                            cellSpacing="0"
                            role="grid"
                            aria-describedby="dataTable_info"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th style={{width:'20%'}}>Property Name</th>
                                <th style={{width:'20%'}}>Location</th>
                                <th style={{width:'15%'}}>Actual Price</th>
                                <th>Promo Price</th>
                                <th>Survey Price</th>
                                <th>Developmental Levy</th>
                                <th>C of O</th>
                                <th>Deed of Assignment</th>
                                <th style={{width:'100px'}}>Action</th>
                              </tr>
                            </thead>
                            <tbody>{renderData()}</tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </>}
                
              </div>
        
        </div>
      </div>
    </div>
  </div>

  {/* Property details */}
<Modal show={showMore} onHide={closeMore} size="lg">
<Modal.Header closeButton>
    <Modal.Title><FaPlusCircle /> Property Details</Modal.Title>
</Modal.Header>
<Modal.Body>
  <div className="property-img">
 
<img src={property !== null ? `https://api.zealandproperties.com/images/${property.property.property_images}` : ""} 
           alt={property !== null ? property.property.property_name : ""}
       />

  </div>
	<div className="form-group row">
		<div className="col-lg-6">
			<strong>Property Name: </strong> {property !== null ? property.property.property_name : ""}
		</div>
		<div className="col-lg-6">
			<strong>Location: </strong> {property !== null ? property.property.location : ""}
		</div>
	</div>
	<div className="form-group row mt-1">
		<div className="col-lg-6">
			<strong>Actual Price: </strong> &#x20A6; {property !== null ? <NumericFormat className="format-number" value={property.property.actual_price} thousandSeparator={true} /> : ""}
		</div>
		<div className="col-lg-6">
			<strong>Promo Price: </strong> &#x20A6; {property !== null ? <NumericFormat className="format-number" value={property.property.promo_price} thousandSeparator={true} /> : ""}
		</div>
	</div>

	<div className="form-group row mt-1">
		<div className="col-lg-6">
			<strong>Promo Details: </strong> {property !== null ? property.property.promo_details : ""}
		</div>
		<div className="col-lg-6">
			<strong>Survey Price: </strong>&#x20A6;  {property !== null ? <NumericFormat className="format-number" value={property.property.survey_price} thousandSeparator={true} /> : ""}
		</div>
	</div>

	<div className="form-group row mt-1">
		<div className="col-lg-6">
			<strong>C of O / Deed of Assignment: </strong> {property !== null ? `${property.property.c_of_o}/${property.property.deed_of_assignment}`
 : ""}
		</div>
		<div className="col-lg-6">
			<strong>Date Registered: </strong> {property !== null ? 
			 
			 dateFormat( property.property.created_at, "dddd, mmmm dS, yyyy, h:MM:ss TT")
			 : ""}
		</div>
	</div>
	<div className="form-group description">
  <strong>Property Description: </strong><span dangerouslySetInnerHTML={{__html:property !== null ? property.property.description
			 : ""}}></span>
  </div>
        
</Modal.Body>
<Modal.Footer>



    <Button variant="primary" onClick={closeMore}>
      <><FaTimes /> Close</>
    </Button>

</Modal.Footer>
</Modal>
    </>
  )
}

export default Properties