
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const FormatableTextArea = ({value, handleChange}) => {
    return (<>
        <ReactQuill value={value} onChange={handleChange}  />
    </>)
}

export default FormatableTextArea