const Forminputgroup = ({ inputLabel, inputType, inputName, inputRequire, value, onChange, disabled }) => {
  const checkRequired = ()=>{
    return inputRequire ? '*' : ''
  }

  return (
    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 input-wrapper new-form">
    
      <input
        type={inputType}
        className="form-control"
        name={inputName}
        id={inputName}
        autoComplete="off"
        placeholder={`${inputLabel} ${checkRequired()}`}
        required={inputRequire}
        value={value} 
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
};

export default Forminputgroup;
