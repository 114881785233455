import React from 'react'
import Footer from '../../component/Footer'
import PropertyContent from '../admincomponent/PropertyContent'

const Propertys = () => {
  return (
    <>
      <PropertyContent />
      <Footer />
    </>
  )
}

export default Propertys