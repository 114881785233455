import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Sidebar from '../usercomponent/Sidebar'
import UserTopBar from '../../component/UserTopbar'
import Forminputshort from '../../component/reusables/Forminputshort'
import {  FaPlus} from 'react-icons/fa'
import { ImSpinner3 } from "react-icons/im";
import { getUser } from '../../features/user/userSlice'
import { toast } from 'react-toastify'
import dateFormat from 'dateformat'
import { addBank, getUserBank, updateUser } from '../../util/services'
import Empty from '../../component/Empty'
import { FiBookOpen } from 'react-icons/fi'


const Bank = () => {
  const user = JSON.parse(localStorage.getItem("user"));
 

const [profileData, setProfileData] = useState({
    bankName:  '',
    accountName: '',
    accountNumber:'',
    userId: user.id,  
})

const [loading, setLoading] = useState(false)
const [banks, setBanks] = useState([])


useEffect(()=>{
    getMybank(user.id)
},[])

  
const handlechange = (e) =>{
    setProfileData({...profileData, [e.target.name]:e.target.value})
}

const getMybank = async(id) =>{
    const {data} = await getUserBank(id)
   
    setBanks(data.data)
}
const onSubmit = async (e) =>{
    e.preventDefault();
  
   try {
    setLoading(true)
    const resp= await addBank(profileData)

    if(resp.status === 201){
      toast.success("Bank added successfully!")
    }
    setLoading(false)
   } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    toast.error(message)
    setLoading(false)
    console.log(error);
   }
   
}



const referraList = () => {
  if (banks !== null && banks.length > 0) {
    return banks.map((item) => {
      return (
        <tr key={item.id}>
    <td>{dateFormat(item.created_at, "dddd, mmmm dS, yyyy, h:MM:ss TT")}</td>
    <td>{item.bank_name}</td>
    <td>{item.account_name}</td>
    <td>{item.account_number}</td> 
    <td>Edit</td> 
        </tr>
      );
    });
  } else {
    <div className="alert alert-primary"> No item found</div>;
  }
};

  return (
    <div className="inner-page-wrapper">
    <Sidebar />
    <div id="content-wrapper" className="content-wrapper d-flex flex-column">
      {/* Main Content  */}
      <div id="content">
        {/* Topbar */}

        <UserTopBar />

        {/* Begin Page Content  */}
        <div className="container-fluid">
          {/* Page Heading  */}
          <div className="d-sm-flex align-items-center justify-content-between mb-5">
            <div className="page-breadcrumbs">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center text-center">
                  <li className="breadcrumb-item ">Dashboard</li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Bank
                  </li>
                </ol>
              </nav>
            </div>
          </div>

                <div className="row">
                    <div className="col-lg-1 profile-wrapper">
                        {/* <img src={profileImg} alt="" className='profile-image' /> */}
                    </div>
                    <div className="col-lg-11">
                    <form autoComplete="off" onSubmit={onSubmit}>
                <div className="form-header text-left">
                  <h5 className="form-title">
                    My Bank 
                  </h5>
                </div>
                <div className="form-body">
                  <hr />
                  <h5>Account Details </h5>
                  <div className="form-group row">
                    <Forminputshort
                      inputLabel={`Bank Name`}
                      inputName="bankName"
                      inputType={`text`}
                      inputRequired={`required`}
                      onChange={handlechange}
                      value={profileData.bankName}
                    
                    />
                    <Forminputshort
                      inputLabel={`Account Number`}
                      inputName="accountNumber"
                      inputType={`number`}
                      inputRequired={`required`}
                      onChange={handlechange}
                      value={profileData.accountNumber}
                  
                    />
                  </div>
                  <div className="form-group row">
                    
                    <Forminputshort
                      inputLabel={`Account Name`}
                      inputName="accountName"
                      inputType={`text`}
                      inputRequired={`required`}
                      onChange={handlechange}
                      value={profileData.accountName}
                   
                    />
                  </div>
                 
                  <div className="form-group btn-wrap form-btn">
                    <button className="btn btn-lingt btn-shadow btn-round btn-md">
                      Add Bank
                    {loading ? <ImSpinner3 className="icon-spin" /> :  <>&nbsp; <FaPlus /></>}
                    </button>
                  </div>
                </div>
              </form>

              <hr />
	<h5>Account Details</h5>
	
	{
        banks.length > 0 ?
        <table className='table table-stripped table-bordered dataTable'>
				<thead>
					<tr>
                    <th>Date Added</th>
					<th>Bank Name</th>
						<th>Account Name</th>
						<th>Account Number</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
					{referraList()}
					
					
				</tbody>
			</table>
            :
            <Empty title="No Bank Record" details="You have not add any bank" icon={<FiBookOpen className='empty-icon' />} />
    }
      
                    </div>
                </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Bank