import {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import Sidebar from './Sidebar'
import dateFormat from "dateformat";
import { toast } from 'react-toastify'

import {Modal, Button} from 'react-bootstrap'
import AppTopBar from '../../component/AppTopBar'
import { FaEye, FaTrashAlt, FaPlusCircle, FaTimes, FaIndustry } from 'react-icons/fa'

import { deleteUser,  getUsers } from "../../features/user/userSlice"
import {FiChevronLeft, FiChevronsRight,FiChevronsLeft,FiChevronRight} from "react-icons/fi"
import { BsBank} from "react-icons/bs"
import { getAllUsers, getUser } from '../../util/services';
import Empty from '../../component/Empty';
const UserContent = () => {

	const dispatch = useDispatch()
	const [userList, setUserlist] = useState([])
	const [show, setShow] = useState(false)
	const [page, setPage] = useState(1)
  	const [from, setFrom] = useState(null)
  	const [to, setTo] = useState(null)
  	const [total, setTotal] = useState(null)
	const [lPage, setLPage] = useState(null)
	const [loading, setLoading] = useState(false)
	const [isLoading, setIsloading] = useState(false)
	const [profile, setProfile] = useState(null)
	const [referals, setReferal] = useState([])
	const [author, setAuthor] = useState(null)


    const { isLoadingU, isErrorU, isFetchSuccessU,isSuccessU,  users, messageU } =
useSelector((state) => state.user);

const handleClose = () => setShow(false)
const handleShow = () => setShow(true)

const nextPage = () => setPage(prev => prev + 1)
const prevPage = () => setPage(prev => prev - 1)
const lastPage = () => setPage(lPage)
const firstPage =() => setPage(1)

useEffect(() => {
  
  getAllUser(page)

}, [page]);

const handleDelete = (id) =>{
    if(window.confirm("Do you want to delete this user?")){
      if(dispatch(deleteUser(id))){
		toast.success("User deleted successfully")
	  }
      const newData = users.data.filter((item) => item.id !== id)
      setUserlist( newData)
    }
      
  }


  const getAllUser = async(page) =>{
	try {
		setLoading(true)
		const { data} = await getAllUsers(page)
		console.log(data);
		setUserlist(data.data);
		setFrom(data.meta.from)
		setTo(data.meta.to)
		setTotal(data.meta.total)
		setLPage(data.meta.last_page)
		setLoading(false)
	} catch (error) {
		const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        toast.error(message)
	}
  }


const showUser = async(id) =>{
	try {
		setIsloading(true)
		const { data} = await getUser(id)
		console.log(data);
		if(data.success){
			setProfile(data.data)
			setReferal(data.referals)
			setAuthor(data.author)
		}
		setIsloading(false)
	} catch (error) {
		const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        toast.error(message)
	}
  }

  const renderData = () => {
    if (userList && userList.length > 0) {
      return userList.map((item) => {
        return (
          <tr key={item.id}>
			<td>{dateFormat(item.created_at, "dddd, mmmm dS, yyyy")}</td>
            <td>{item.first_name + " "+ item.last_name}</td>
			<td>{item.email}</td>
            <td>{item.phone_number}</td>
            <td>{item.gender}</td>
			<td>{item.address}</td>
			<td>{item.dob}</td>
			<td>{item.bank.length > 0 ? `${item.bank[0].account_name} (${item.bank[0].account_number})` : ''}</td>
			<td>{item.referal_code}</td>
            <td style={{width:'80px'}}>
			<FaTrashAlt className="delete" onClick={() => handleDelete(item.id)}  />{" "}
            <Link to={`#`} onClick={() => 
				{
				showUser(item.id)
				handleShow()
				}
			}><FaEye className="details"/> </Link> 
                         
            </td>
              
          </tr>
        );
      });
    } else {
      <div className="alert alert-primary"> No item found</div>;
    }
  };

  const referraList = () => {
    if (referals !== null && referals.length > 0) {
      return referals.map((item) => {
        return (
          <tr key={item.id}>
			<td>{dateFormat(item.dateRegistered, "dddd, mmmm dS, yyyy, h:MM:ss TT")}</td>
            <td>{item.first_name + " "+ item.last_name}</td>
			<td>{item.email}</td>
            <td>{item.phone_number}</td> 
          </tr>
        );
      });
    } else {
      <div className="alert alert-primary"> No item found</div>;
    }
  };


  return (
	<>
    <div className="inner-page-wrapper">
			
				{/* Sidebar */}
                <Sidebar />
			<div id="content-wrapper" className="content-wrapper d-flex flex-column">

				{/* Main Content  */}
				<div id="content">

					 {/* Topbar */}

				<AppTopBar />

					 {/* Begin Page Content  */}
					<div className="container-fluid">

						{/* Page Heading  */}
						<div className="d-sm-flex align-items-center justify-content-between mb-5">
							
						<div className="page-breadcrumbs">
							<nav aria-label="breadcrumb">
							<ol className="breadcrumb justify-content-center text-center">
								<li className="breadcrumb-item ">Dashboard</li>
								<li className="breadcrumb-item active" aria-current="page">
								 Users
								</li>
							</ol>
							</nav>
              			</div>
						</div>

						
        <div className="row container mt-4 main-wrap">
        <div className="card shadow mb-5">
				

                <div className="card-header py-3 top">
                <h6 className="m-0 font-weight-bold text-primary">Users</h6>
                {/* <div> <Link className="btn btn-primary" to={`#`}> <FaPlusCircle /> Add User </Link></div> */}
              </div>

				<div className="card-body table-responsive">
			{
				loading ? <> Loading....</> :
				<table className='table table-stripped table-bordered dataTable custom-table'>
				<thead>
					<tr>
					<th>Date Registered</th>
						<th>Full Name</th>
						<th>Email</th>
						<th>Phone No</th>
						<th>Gender</th>
                        <th>Address</th>
						<th>Date of Birth</th>
						<th>Account</th>
						<th>Referal Code</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
					{renderData()}
				</tbody>
			</table>
			}
            </div>
			<p className="pagination-bar">
                      <FiChevronsLeft className="link-icon" onClick={() => firstPage()} /> 
                      <FiChevronLeft className="link-icon" onClick={() => prevPage()} />
                       Record {from} to {to} of {total} 
                       <FiChevronRight className="link-icon" onClick={() => nextPage()}/> 
                       <FiChevronsRight className="link-icon" onClick={() => lastPage()} /> 
                       </p>	
            </div>
	
        </div>
			
	</div>
	</div>
	</div>
</div>

<Modal show={show} onHide={handleClose} size="lg">
<Modal.Header closeButton>
    <Modal.Title><FaPlusCircle /> User Info</Modal.Title>
</Modal.Header>
<Modal.Body>
	<div className="form-group row">
		<div className="col-lg-6">
			<strong>First Name: </strong> {profile !== null ? profile.first_name : ""}
		</div>
		<div className="col-lg-6">
			<strong>Last Name: </strong> {profile !== null ? profile.last_name : ""}
		</div>
	</div>

	<div className="form-group row mt-1">
		<div className="col-lg-6">
			<strong>Email Address: </strong> {profile !== null ? profile.email : ""}
		</div>
		<div className="col-lg-6">
			<strong>Phone Number: </strong> {profile !== null ? profile.phone_number : ""}
		</div>
	</div>

	<div className="form-group row mt-1">
		<div className="col-lg-6">
			<strong>Address: </strong> {profile !== null ? profile.address : ""}
		</div>
		<div className="col-lg-6">
			<strong>Gender: </strong> {profile !== null ? profile.gender : ""}
		</div>
	</div>

	<div className="form-group row mt-1">
		<div className="col-lg-6">
			<strong>Referal Code: </strong> {profile !== null ? profile.referal_code: ""}
		</div>
		<div className="col-lg-6">
			<strong>Date Registered: </strong> {profile !== null ? 
			 
			 dateFormat( profile.created_at, "dddd, mmmm dS, yyyy, h:MM:ss TT")
			 : ""}
		</div>
	</div>
	<div className="form-group row mt-1">
		<div className="col-lg-6">
			<strong>Refered By: </strong> {author !== null ? author.first_name+" "+ author.last_name +"("+author.referal_code+")": ""}
		</div>
		
	</div>
	<hr />
	<strong>Bank Details</strong>
	{
		profile !== null && profile.bank === null ?
		
		<Empty type='mini' title="No Bank Details" details="You have not add your bank details" icon={<BsBank className='empty-icon-sm' />} />
		
		
		:
		<div className="form-group row description">
		<div className="col-lg-6">
			<strong>Bank: </strong> {profile !== null && profile.bank.length > 0 ? profile.bank[0].bank_name : ""}
		</div>
		<div className="col-lg-6">
			<strong>Account: </strong> {profile !== null && profile.bank.length > 0 ? `${profile.bank[0].account_name}(${profile.bank[0].account_number})` : ""}
		</div>
	</div>
	}
        
	<hr />
	<h5>My Referral List</h5>
	<p> Your referral count is <span className='ref-count'>{profile !== null && profile.referals !== null ? referals.length : 0}</span></p>
	<table className='table table-stripped table-bordered dataTable'>
				<thead>
					<tr>
					<th>Date Registered</th>
						<th>Full Name</th>
						<th>Email</th>
						<th>Phone No</th>
					</tr>
				</thead>
				<tbody>
					{referraList()}
					
					
				</tbody>
			</table>
</Modal.Body>
<Modal.Footer>



    <Button variant="primary" onClick={handleClose}>
      <><FaTimes /> Close</>
    </Button>

</Modal.Footer>
</Modal>
</>
  )
}

export default UserContent