import {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import Sidebar from './Sidebar'
import { NumericFormat } from 'react-number-format'
import { Modal, Button } from 'react-bootstrap'
import AppTopBar from '../../component/AppTopBar'
import { FaEye, FaTrashAlt, FaPlusCircle, FaTimes, FaInfoCircle, FaMapMarkerAlt, FaPhoneAlt, FaEnvelopeOpen } from 'react-icons/fa'
import { getBuyer, getBuyers, getUser } from '../../util/services'
import { FiMoreVertical } from 'react-icons/fi'


const CommisionContent = () => {

	const [buyers, setBuyers] = useState([])
	const [buyer, setBuyer] = useState(null)
	const [show, setShow] = useState(false)
	const [loading, setLoading] = useState(false)
	const [load, setLoad] = useState(false)
	const [firstReferal, setFirst] = useState(null)

	useEffect(()=>{
		fetchBuyers()
	},[])

	const handleClose = () => setShow(false)
	const handleShow =() => setShow(true)

	// get all buyers
	const fetchBuyers = async () =>{
		try {
			setLoading(true)
			const { data } = await getBuyers()
			setBuyers(data.data)
			setLoading(false)
		} catch (error) {
			console.log(error);
			setLoading(false)
		}
	}

	const showCommision = async (id) =>{
		try {
			const { data } = await getBuyer(id)
			setBuyer(data)
		} catch (error) {
			console.log(error);
		}
	}

	const loadUser = async (id) =>{
		try {
			setLoad(true)
			const { data } = await getUser(id)
			if(data) {
				setLoad(false)
				setFirst(data.data)
			}
		} catch (error) {
			console.log(error);
			setLoad(false)
		}
	}

console.log(buyers);
	const renderData = () => {
		if (buyers && buyers.length > 0) {
		  return buyers.map((item) => {
			return (
			  <tr key={item.id}>
				<td>{item.property_id}</td>
				<td>{item.consultant_id}</td>
				<td>{item.client_id}</td>
				<td><NumericFormat prefix='NGN' value={item.amount_paid} thousandSeparator={true} className="format-number" /></td>
				
				<td>{item.purchase_date}</td>  
				<td>{item.description}</td> 
				<td style={{width:'150px'}}>
				  <button className='customeBtn custom-secondary' onClick={() =>{
					showCommision(item.id)
					setShow(true)
				  }}><FiMoreVertical /> Details
				  </button></td> 
			  </tr>
			);
		  });
		} else {
		  <div className="alert alert-primary"> No item found</div>;
		}
	  };

	  const directCommision = buyer !== null ? JSON.parse(buyer.data.direct_ref) : ""
	  const firstCommision = buyer !== null ? JSON.parse(buyer.data.first_ref) : ""
	  const secondCommision = buyer !== null ? JSON.parse(buyer.data.second_ref) : ""
	 
  return (
	<>
    <div className="inner-page-wrapper">
			
				{/* Sidebar */}
                <Sidebar />
			<div id="content-wrapper" className="content-wrapper d-flex flex-column">

				{/* Main Content  */}
				<div id="content">

					 {/* Topbar */}

				<AppTopBar />

					 {/* Begin Page Content  */}
					<div className="container-fluid">

						{/* Page Heading  */}
						<div className="d-sm-flex align-items-center justify-content-between mb-5">
							
						<div className="page-breadcrumbs">
							<nav aria-label="breadcrumb">
							<ol className="breadcrumb justify-content-center text-center">
								<li className="breadcrumb-item ">Dashboard</li>
								<li className="breadcrumb-item active" aria-current="page">
								 Commisions
								</li>
							</ol>
							</nav>
              			</div>
						</div>

						
        <div className="row container mt-4 main-wrap">
        <div className="card shadow mb-5">
				

                <div className="card-header py-3 top">
                <h6 className="m-0 font-weight-bold text-primary">Commisions/Referals</h6>
                {/* <div> <Link className="btn btn-primary" to={`#`}> <FaPlusCircle /> Add Property </Link></div> */}
              </div>

				<div className="card-body table-responsive">
			<table className='table table-stripped table-bordered dataTable custom-table'>
				<thead>
					<tr>
                    <th>Commission For:</th>
						<th>Consultant</th>
						<th>Client</th>
						<th>Amount Paid</th>
						<th>Date Purchase</th>
						<th>Description</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
					{renderData()}
				</tbody>
			</table>
            </div>
            </div>
	
        </div>
					
	</div>
	</div>
	</div>
</div>


<Modal show={show} onHide={handleClose} size="lg">
<Modal.Header closeButton>
    <Modal.Title> <span><FaInfoCircle /> Commision Details</span> </Modal.Title>
</Modal.Header>
<Modal.Body>
	
<div className="form-group row">
<strong>Property Details: </strong> 
		<div className="col-lg-6">
			{buyer !== null ? buyer.property[0].property_name
 : ""} ({buyer !== null ? buyer.property[0].location
	: ""})
		</div>
		<div className="col-lg-6">
			<strong>Price: </strong> {buyer !== null ? 
			
			<NumericFormat prefix='NGN' value={buyer.property[0].actual_price} thousandSeparator={true} className="format-number" />
 			: ""} 
			<br /> <strong>Promo: </strong> {buyer !== null ? 
			<NumericFormat prefix='NGN' value={buyer.property[0].promo_price} thousandSeparator={true} className="format-number" />
	: ""}
		</div>
	</div>

	<hr />

	<div className="form-group row">
<strong>Client Details: </strong> 
		<div className="col-lg-6">
			{buyer !== null ? buyer.client[0].client_name
 : ""} ({buyer !== null ? buyer.client[0].email
	: ""})
		</div>
		<div className="col-lg-6">
			<strong>Address: <FaMapMarkerAlt /> </strong> {buyer !== null ? buyer.client[0].address : ""} 
			<br /> <strong>Phone: <FaPhoneAlt /> </strong> {buyer !== null ? buyer.client[0].phone_number : ""} 

		</div>
	</div>

	<hr />

	<div className="form-group row">
<strong>Consultant Details: </strong> 
		<div className="col-lg-6">
		<strong>Name: </strong>	 {buyer !== null ? buyer.consultant[0].first_name +" " +buyer.consultant[0].last_name  : ""} <br/>
		<strong>Email: <FaEnvelopeOpen /> </strong> {buyer !== null ? buyer.consultant[0].email: ""}<br/>
		<strong>Phone: <FaPhoneAlt /> </strong> {buyer !== null ? buyer.consultant[0].phone_number: ""}
		</div>

		<div className="col-lg-6">
			<strong>Bank:  </strong> {buyer !== null ? buyer.consultant[0].bank_name : ""} 
			<br /> <strong>Account Name: </strong> {buyer !== null ? buyer.consultant[0].account_name : ""} 
			<br /> <strong>Account Number:  </strong> {buyer !== null ? buyer.consultant[0].account_number : ""} 
			<br /><strong>Commission:  </strong> {directCommision !== "" ? 
			directCommision.amount 
			: ""} 
			({directCommision !== "" ? directCommision.commision : ""}%) 
		


		</div>
	</div>
	<hr />
	{
		firstCommision.id !== "" ? <><span className='customeBtn' onClick={()=>loadUser(firstCommision.id)}>Show Second Referal</span> </> : <></>
	}

{
	firstReferal === null ? <></> :

	<div className="form-group row">
	<strong>Second Referal Details: </strong> 
		<div className="col-lg-6">
		<strong>Name: </strong>	 {firstReferal !== null ? firstReferal.first_name +" " +firstReferal.last_name  : ""} <br/>
		<strong>Email: <FaEnvelopeOpen /> </strong> {firstReferal !== null ? firstReferal.email: ""}<br/>
		<strong>Phone: <FaPhoneAlt /> </strong> {firstReferal !== null ? firstReferal.phone_number: ""}
		</div>

		<div className="col-lg-6">
			<strong>Bank:  </strong> {firstReferal !== null ? firstReferal.bank_name : ""} 
			<br /> <strong>Account Name: </strong> {firstReferal !== null ? firstReferal.account_name : ""} 
			<br /> <strong>Account Number:  </strong> {firstReferal !== null ? firstReferal.account_number : ""} 
			<br /><strong>Commission:  </strong> {firstCommision !== "" ? 
			firstCommision.amount 
			: ""} 
			({firstCommision !== "" ? firstCommision.commision : ""}%) 
		


		</div>
	</div>
}

</Modal.Body>
<Modal.Footer>



    <Button variant="primary" onClick={handleClose}>
      <><FaTimes /> Close</>
    </Button>

</Modal.Footer>
</Modal>

</>
  )
}

export default CommisionContent