const SelectConsultant = ({ selectName, onChange, itemList, selectLabel,selectOption }) => {
  
    const renderList = () =>{
      if(itemList && itemList.length !== 0){
          return itemList.map((item)=>{
            return <option key={item.id} value={item.id}>{`${item.first_name} ${item.last_name}`}</option>
          })
      }else{
        return <option>No Item</option>
      }
    }
  
    return (
      <div className="col-lg-6 form-group input-wrapper">
      
        <select
          name={selectName}
          // value={value}
          onChange={onChange}
          className="form-control"
          
        >
          <option>{ selectOption}</option>
          {renderList()}
        
        </select>
      </div>
    );
  };
  
  export default SelectConsultant;
  