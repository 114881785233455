import axios from "axios"

const baseUrl = process.env.REACT_APP_BASE_URL
const apiVersion = process.env.REACT_APP_API_VERSION

const apiUrl = `${baseUrl}/${apiVersion}`

const createUser = async (inputData, token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.post(`${apiUrl}/users`, inputData, config);
    return data
}

const updateUser = async (inputData, id, token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.put(`${apiUrl}/users/${id}`, inputData, config);
    return data
}

// @ retun list of directors for a particular business
const getUsers = async ( token, page) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.get(`${apiUrl}/users?page=${page}`, config);
    return data
}

const getStat = async ( token) =>{

  const config = {
      headers :{
        Authorization: `Bearer ${token}`
      }
    }
    const { data } = await axios.get(`${apiUrl}/stat`, config);
  return data
}

// @ retun list of directors for a particular business
const getUser = async (id, token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.get(`${apiUrl}/users/${id}`, config);
    return data
}

// @ function return a single director
const deleteUser = async (id, token) =>{

  const config = {
      headers :{
        Authorization: `Bearer ${token}`
      }
    }
    const { data } = await axios.delete(`${apiUrl}/users/${id}`, config);
  return data
}

const userService = {
    createUser,  
    getUser,
    getUsers,
    updateUser,
    deleteUser,
     getStat
}

export default userService