import Footer from "../../component/Footer"
import UserDashboard from "../usercomponent/UserDashboard"

const Dashboard = () => {
  return (
    <>
      <UserDashboard />
      <Footer />
    </>
  )
}

export default Dashboard