import { Link } from "react-router-dom";

import logo from "../../assets/img/Zealand.png";
import { FaListUl,  FaKey, FaUserCircle, FaSignOutAlt, FaArrowAltCircleDown, FaArrowLeft, FaArrowsAltH } from "react-icons/fa";

import { useRecoilState } from "recoil";
import { sidebarAtom } from "../atom/userAtom";
import { useEffect, useRef } from "react";
const Sidebar = () => {
  const [toggleNav, setToggleNav] = useRecoilState(sidebarAtom)

  const sidebarRef = useRef()

  const handleToggle = () =>{
    setToggleNav(!toggleNav)
  }
  
  const handleLogout = () =>{
    localStorage.removeItem("user")
  }

  useEffect(() => {
    // Function to handle clicks outside the sidebar
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setToggleNav(false);
      }
    };

    if (toggleNav) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [toggleNav]);

  return (
    <ul
   
      className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ${toggleNav ? 'openSidebar' : ''}`}
      ref={sidebarRef}
    >
      {/* <!-- Sidebar - Brand --> */}

      <Link
        className="sidebar-brand d-flex align-items-center justify-content-center"
        to={`/`}
      >
        <img
          className="logo sidebar-brand-text mx-3"
          src={logo}
          alt=""
        />
       
      </Link>
      <div className="hide-bar-icon" onClick={()=> setToggleNav(!toggleNav)}>
        <FaArrowsAltH  />
        </div>
      {/* <!-- Divider --> */}
      <hr className="sidebar-divider my-0" />

      {/* <!-- Nav Item - Dashboard --> */}
      <li className="nav-item active">
        <Link className="nav-link" to={`/user/dashboard`}>
          <i className="bi bi-speedometer2 bi-fw"></i> <span>Dashboard</span>
        </Link>
      </li>

      <li className="nav-item">
        <a
          className="nav-link"
          href="#"
          data-bs-toggle="collapse"
          data-bs-target="#collapseProfile"
          aria-expanded="false"
          aria-controls="collapseProfile"
        >
            <i className="bi bi-person mr-2 text-gray-500"></i>{" "}
          <span>Profile</span>
        </a>

        <ul
          id="collapseProfile"
          className="collapse sub-menus"
          aria-labelledby="headingTwo"
          data-parent="#collapseProfile"
        >
          <li className="nav-item collapse-item">
            <Link
              className="collapse-item"
              to={`/user/profile`}
            >
              <FaUserCircle className="mr-1 text-gray-400" />
              My Profile
            </Link>
          </li>

          <li className="nav-item collapse-item">
            <Link className="collapse-item" to={`/user/reset-password`}>
              <FaKey className="mr-1 text-gray-400" /> Reset Password
            </Link>
          </li>


        </ul>
      </li>

      <li className="nav-item ">
        <Link className="nav-link" to={`/user/bank`}>
          <i className="bi bi-credit-card-2-back"></i> Add Bank
        </Link>
      </li>

      <li className="nav-item">
        <a
          className="nav-link"
          href="#"
          data-bs-toggle="collapse"
          data-bs-target="#collapseBank"
          aria-expanded="false"
          aria-controls="collapseBank"
        >
          <i className="bi bi-bag-dash mr-2 text-gray-400"></i>{" "}
         
          <span>My Team</span>
        </a>

        <ul
          id="collapseBank"
          className="collapse sub-menus"
          aria-labelledby="headingTwo"
          data-parent="#collapseBank"
        >
          <li className="nav-item collapse-item">
            <Link
              className="collapse-item"
              to={`/user/referals`}
            >
              <FaListUl className="mr-1 text-gray-400" />
              My Referals
            </Link>
          </li>

        
        </ul>
      </li>

      


      <li className="nav-item ">
        <Link className="nav-link" to={`/user/properties`}>
          <i className="bi bi-credit-card-2-back"></i> Properties
        </Link>
      </li>

      <li className="nav-item ">
        <Link className="nav-link" to={`/`} onClick={handleLogout}>
          <FaSignOutAlt /> Logout
        </Link>
      </li>
     
    </ul>
  );
};

export default Sidebar;
