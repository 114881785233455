import axios from "axios"

const baseUrl = process.env.REACT_APP_BASE_URL
const apiVersion = process.env.REACT_APP_API_VERSION

const apiUrl = `${baseUrl}/${apiVersion}`

const createClient = async (inputData, token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.post(`${apiUrl}/clients`, inputData, config);
    return data
}

const updateClient = async (inputData, id, token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.put(`${apiUrl}/clients/${id}`, inputData, config);
    return data
}

// @ retun list of directors for a particular business
const getClients = async ( token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.get(`${apiUrl}/clients`, config);
    return data
}

// @ retun list of directors for a particular business  return  axios.get(`${apiUrl}/users?page=${page}&include=true`);
const getClient = async (id, token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.get(`${apiUrl}/clients/${id}`, config);
    return data
}

// @ function return a single director
const deleteClient = async (id, token) =>{

  const config = {
      headers :{
        Authorization: `Bearer ${token}`
      }
    }
    const { data } = await axios.delete(`${apiUrl}/clients/${id}`, config);
  return data
}

const clientService = {
    createClient,  
    getClient,
    getClients,
    updateClient,
    deleteClient
}

export default clientService