import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import propertyReducer from '../features/property/propertySlice';
import clientReducer from '../features/client/clientSlice';
import userReducer from '../features/user/userSlice';


export const store = configureStore({
  reducer: {
    auth: authReducer,
    property: propertyReducer,
    client: clientReducer,
    user: userReducer
  },
});
