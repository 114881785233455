const SelectProperty = ({ selectName, value, onChange, itemList, selectLabel,selectOption }) => {
  
  const renderList = () =>{
    if(itemList && itemList.length !== 0){
        return itemList.map((item)=>{
          return <option key={item.id} value={item.property_name}>{item.property_name}</option>
        })
    }else{
      return <option>No Item</option>
    }
  }

  return (
    <div className="col-md-6 form-group input-wrapper">
    
      <select
        name={selectName}
        value={value}
        onChange={onChange}
        className="form-control"
        
      >
        <option>{ selectOption ? selectOption : selectLabel}</option>
        {renderList()}
      
      </select>
    </div>
  );
};

export default SelectProperty;
