import React from 'react'
import Footer from '../../component/Footer'
import CommisionContent from '../admincomponent/CommisionContent'

const Commision = () => {
  return (
    <>
      <CommisionContent />
      <Footer />
    </>
  )
}

export default Commision