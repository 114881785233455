import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Sidebar from '../usercomponent/Sidebar'
import UserTopBar from '../../component/UserTopbar'
import Forminputshort from '../../component/reusables/Forminputshort'
import { FaEdit} from 'react-icons/fa'
import { ImSpinner3 } from "react-icons/im";
import { getUser } from '../../features/user/userSlice'
import { toast } from 'react-toastify'
import dateFormat from 'dateformat'
import { updateUser } from '../../util/services'
import { FiEdit } from 'react-icons/fi'
import FormSelectComponent from '../../component/reusables/FormSelectComponent'
import { genderList } from '../../util/data'

const Profile = () => {
  const user = JSON.parse(localStorage.getItem("user"));
 
  const initialData  = {
    first_name: user !==null ? user.first_name : '',
    last_name: user !==null ? user.last_name :'',
    phone_number:user !==null ? user.phone_number :'',
    email: user !==null ? user.email :'',
    address: user !==null ? user.address :'',
    gender: user !==null ? user.gender :'',
    dob: user !==null ? user.dob :'',
   
   
}


const [profileData, setProfileData] = useState(initialData)
const [toggleEdit, setToggleedit] = useState(true)
const [loading, setLoading] = useState(false)

const {  isErrorU, isFetchSuccessU, profile,  messageU } =
useSelector((state) => state.user);

const dispatch = useDispatch()
useEffect(() => {
  if (isErrorU) {
    toast.error(messageU);
  }
  dispatch(getUser(user.id));
  
}, [isFetchSuccessU]);

  const handleToggle = ()=>{
    setToggleedit(!toggleEdit)
}
const handlechange = (e) =>{
    setProfileData({...profileData, [e.target.name]:e.target.value})
}

const onSubmit = async (e) =>{
    e.preventDefault();
  
   try {
    setLoading(true)
    const { data } = await updateUser(profileData, user.id)
    if(data.success){
      toast.success(data.message)
      console.log(data);
    }
    setLoading(false)
   } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    toast.error(message)
    setLoading(false)
   }
   
}

const handleImageChange = (e) =>{
    setProfileData({...profileData, profile_picture:e.target.files[0]})
}

const referraList = () => {
  if (profile !== null && profile.referals.length > 0) {
    return profile.referals.map((item) => {
      return (
        <tr key={item.id}>
    <td>{dateFormat(item.dateRegistered, "dddd, mmmm dS, yyyy, h:MM:ss TT")}</td>
          <td>{item.first_name + " "+ item.last_name}</td>
    <td>{item.email}</td>
          <td>{item.phone_number}</td> 
        </tr>
      );
    });
  } else {
    <div className="alert alert-primary"> No item found</div>;
  }
};

  return (
    <div className="inner-page-wrapper">
    <Sidebar />
    <div id="content-wrapper" className="content-wrapper d-flex flex-column">
      {/* Main Content  */}
      <div id="content">
        {/* Topbar */}

        <UserTopBar />

        {/* Begin Page Content  */}
        <div className="container-fluid">
          {/* Page Heading  */}
          <div className="d-sm-flex align-items-center justify-content-between mb-5">
            <div className="page-breadcrumbs">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center text-center">
                  <li className="breadcrumb-item ">Dashboard</li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Profile
                  </li>
                </ol>
              </nav>
            </div>
          </div>

                <div className="row">
                    <div className="col-lg-1 profile-wrapper">
                        {/* <img src={profileImg} alt="" className='profile-image' /> */}
                    </div>
                    <div className="col-lg-11">
                    <form autoComplete="off" onSubmit={onSubmit}>
                <div className="form-header text-left">
                  <h5 className="form-title">
                    My Profile <FaEdit className='edit-icon'  onClick={handleToggle}/>
                  </h5>
                </div>
                <div className="form-body">
                  

                  <div className="form-group row">
                    <Forminputshort
                      inputLabel={`First Name`}
                      inputName="first_name"
                      inputType={`text`}
                      inputRequired={`required`}
                      onChange={handlechange}
                      value={profileData.first_name}
                      disable={toggleEdit}
                    />
                    <Forminputshort
                      inputLabel={`Last Name`}
                      inputName="last_name"
                      inputType={`text`}
                      inputRequired={`required`}
                      onChange={handlechange}
                      value={profileData.last_name}
                      disable={toggleEdit}
                    />
                  </div>

                  <div className="form-group row">
                    <Forminputshort
                      inputLabel={`Email Adress`}
                      inputName="email"
                      inputType={`email`}
                      inputRequired={`required`}
                      onChange={handlechange}
                      value={profileData.email}
                      disable={toggleEdit}
                    />
                    <Forminputshort
                      inputLabel={`Phone Number`}
                      inputName="phone_number"
                      inputType={`text`}
                      inputRequired={`required`}
                      onChange={handlechange}
                      value={profileData.phone_number}
                      disable={toggleEdit}
                    />
                  </div>

                  <div className="form-group row">
                    <Forminputshort
                      inputLabel={`Address`}
                      inputName="address"
                      inputType={`text`}
                      inputRequired={`required`}
                      onChange={handlechange}
                      value={profileData.address}
                      disable={toggleEdit}
                    />
                    
                    <FormSelectComponent
                      selectName="gender"
                      selectLabel="Select Gender"
                      selectOption={profileData.gender}
                      value={profileData.gender}
                      onChange={handlechange}
                      itemList={genderList}
                    />
                  </div>

                  <div className="form-group row">
                    <Forminputshort
                      inputLabel={`Date of Birth`}
                      inputName="dob"
                      inputType={`date`}
                      inputRequired={`required`}
                      onChange={handlechange}
                      value={profileData.dob}
                      disable={toggleEdit}
                    />

{
                    toggleEdit ?
                    <></>
                    :
                    <div className="form-group row">
                    <div className="form-group input-wrapper col-lg-12">
                      <label htmlFor="" className="input-label">
                        Profile Picture
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name="profile_picture"
                        onChange={handleImageChange}
                      />
                    </div>
                  </div>
                 }
                  </div>

                  {/* <hr />
                  <h5>Account Details </h5>
                  <div className="form-group row">
                    <Forminputshort
                      inputLabel={`Bank Name`}
                      inputName="bankName"
                      inputType={`text`}
                      inputRequired={`required`}
                      onChange={handlechange}
                      value={profileData.bankName}
                      disable={toggleEdit}
                    />
                    <Forminputshort
                      inputLabel={`Account Number`}
                      inputName="accountNumber"
                      inputType={`number`}
                      inputRequired={`required`}
                      onChange={handlechange}
                      value={profileData.accountNumber}
                      disable={toggleEdit}
                    />
                  </div>
                  <div className="form-group row">
                    
                    <Forminputshort
                      inputLabel={`Account Name`}
                      inputName="accountName"
                      inputType={`text`}
                      inputRequired={`required`}
                      onChange={handlechange}
                      value={profileData.accountName}
                      disable={toggleEdit}
                    />
                  </div> */}
                 
                 


                  <div className="form-group btn-wrap form-btn">
                    <button className="btn btn-lingt btn-shadow btn-round btn-md">
                      Update Profile
                    {loading ? <ImSpinner3 className="icon-spin" /> :  <>&nbsp; <FiEdit /></>}
                    </button>
                  </div>
                </div>
              </form>

              {/* <hr />
	<h5>My Referral List</h5>
	<p> Your referral count is <span className='ref-count'>{profile !== null ? profile.referals.length : 0}</span></p>
	<table className='table table-stripped table-bordered dataTable'>
				<thead>
					<tr>
					<th>Date Registered</th>
						<th>Full Name</th>
						<th>Email</th>
						<th>Phone No</th>
					</tr>
				</thead>
				<tbody>
					{referraList()}
					
					
				</tbody>
			</table> */}
      
                    </div>
                </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Profile