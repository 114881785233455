import { Link } from "react-router-dom";
import logo from "../../assets/img/Zealand.png";
import {  FaKey,  FaUserCircle } from "react-icons/fa";
import { FiUsers, FiHome, FiLogOut, FiDollarSign, FiUserX, FiUser} from "react-icons/fi"
import { useEffect, useRef } from "react";
import { useRecoilState } from "recoil";
import { adminSidebarAtom } from "../../user/atom/userAtom";

const Sidebar = () => {
  const [toggleNav, setToggleNav] = useRecoilState(adminSidebarAtom)

  const sidebarRef = useRef()

  const handleLogout = () =>{
    localStorage.removeItem("user")
  }

  useEffect(() => {
    // Function to handle clicks outside the sidebar
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setToggleNav(false);
      }
    };

    if (toggleNav) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [toggleNav]);

  return (
    <ul
    className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ${toggleNav ? 'openSidebar' : ''}`}
    ref={sidebarRef}
    >
      {/* <!-- Sidebar - Brand --> */}

      <Link
        className="sidebar-brand d-flex align-items-center justify-content-center"
        to={`/`}
      >
        <img
          className="logo sidebar-brand-text mx-3"
          src={logo}
          alt="giving.ng"
        />
      </Link>

      {/* <!-- Divider --> */}
      <hr className="sidebar-divider my-0" />

      {/* <!-- Nav Item - Dashboard --> */}
      <li className="nav-item active">
        <Link className="nav-link" to={`/admin/dashboard`}>
          <i className="bi bi-speedometer2 bi-fw"></i> <span>Dashboard</span>
        </Link>
      </li>

      <li className="nav-item">
        <a
          className="nav-link"
          href="#"
          data-bs-toggle="collapse"
          data-bs-target="#collapseProfile"
          aria-expanded="false"
          aria-controls="collapseProfile"
        >
            <FiUser className="bi bi-person mr-2 text-gray-600" />{" "}
          <span>Profile</span>
        </a>

        <ul
          id="collapseProfile"
          className="collapse sub-menus"
          aria-labelledby="headingTwo"
          data-parent="#collapseProfile"
        >
          <li className="nav-item collapse-item">
            <Link
              className="collapse-item"
              to={`/admin/profile`}
            >
              <FaUserCircle className="mr-1 text-gray-600" />
              My Profile
            </Link>
          </li>


          <li className="nav-item collapse-item">
            <Link className="collapse-item" to={`/admin/reset-password`}>
              <FaKey className="mr-1 text-gray-400" /> Reset Password
            </Link>
          </li>

        </ul>
      </li>

      <li className="nav-item ">
        <Link className="nav-link" to={`/admin/users`}>
          <FiUsers className="bi bi-credit-card-2-back" /> Users
        </Link>
      </li>

      <li className="nav-item ">
        <Link className="nav-link" to={`/admin/properties`}>
          <FiHome className="bi bi-credit-card-2-back" /> Properties
        </Link>
      </li>

      <li className="nav-item ">
        <Link className="nav-link" to={`/admin/clients`}>
          <FiUsers className="bi bi-credit-card-2-back" /> Clients
        </Link>
      </li>

      <li className="nav-item ">
        <Link className="nav-link" to={`/admin/commision`}>
          <FiDollarSign className="bi bi-credit-card-2-back" /> Commisions
        </Link>
      </li>

      <li className="nav-item ">
        <Link className="nav-link" to={`/admin`} onClick={handleLogout}>
          <FiLogOut className="bi bi-credit-card-2-back" /> Logout
        </Link>
      </li>

    </ul>
  );
};

export default Sidebar;
