import axios from "axios"

const baseUrl = process.env.REACT_APP_BASE_URL
const apiVersion = process.env.REACT_APP_API_VERSION

const apiUrl = `${baseUrl}/${apiVersion}`

const createProperty = async (inputData) =>{

    // const config = {
    //     headers :{
    //       Authorization: `Bearer ${token}`
    //     }
    //   }
      const { data } = await axios.post(`${apiUrl}/properties`, inputData);
    return data
}

const updateProperty = async (inputData, id, token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.put(`${apiUrl}/properties/${id}`, inputData, config);
    return data
}

// @ retun list of directors for a particular business
const getProperties = async ( token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.get(`${apiUrl}/properties`, config);
    return data
}

// @ retun list of directors for a particular business
const getProperty = async (id, token) =>{

    const config = {
        headers :{
          Authorization: `Bearer ${token}`
        }
      }
      const { data } = await axios.get(`${apiUrl}/properties/${id}`, config);
    return data
}

// @ function return a single director
const deleteProperty = async (id, token) =>{

  const config = {
      headers :{
        Authorization: `Bearer ${token}`
      }
    }
    const { data } = await axios.delete(`${apiUrl}/properties/${id}`, config);
  return data
}

const propertyService = {
    createProperty,  
    getProperty,
    getProperties,
    updateProperty,
    deleteProperty
}

export default propertyService