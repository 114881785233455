import { FaEye, FaEyeSlash} from 'react-icons/fa'
const PasswordInput = ({ inputLabel, inputType, inputName, inputRequire, value, onChange, toggle, handleToggele }) => {
    const checkRequired = ()=>{
      return inputRequire ? '*' : ''
    }
  
    return (
      <div className="form-group input-wrapper pwd col-lg-12 col-md-12 col-sm-12 col-xs-12">
   
        <input
          type={inputType}
          className="form-control"
          name={inputName}
          id={inputName}
          autoComplete="off"
          placeholder={`${inputLabel} ${checkRequired()}`}
          required={inputRequire}
          value={value} 
          onChange={onChange}
        />
         <div className="icon-wrap" onClick={handleToggele}>
         {toggle ? <FaEyeSlash  /> : <FaEye  />}
         </div>
      </div>
    );
  };
  
  export default PasswordInput;
  