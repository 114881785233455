import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from './authService'



const initialState = {
    user: null,
    isError: false,
    isSuccess:false,
    isLoading:false,
    message:''
}

// Register new users
export const register = createAsyncThunk('auth/register', async (user, thunkAPI) =>{
    
    try {
        return await authService.register(user)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})


// Login users
export const login = createAsyncThunk('auth/login', async (user, thunkAPI) =>{
    try {
        return await authService.login(user)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || 
        error.message || 
        error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

// Login users
export const logout = createAsyncThunk('auth/logout',  async(thunkAPI) =>{
   
     try {
        const resp = JSON.parse(localStorage.getItem("user"));
        return await authService.logout(resp.token)
     } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || 
        error.message || 
        error.toString()

        return thunkAPI.rejectWithValue(message)
     }
})


export const authSlice = createSlice({
    name:'auth',
    initialState,
    reducers:{
        reset:(state) =>{
            state.isError = false
            state.isLoading = false
            state.isSuccess = false
            state.message = ""
        }
    },
    extraReducers: (builder) =>{
        builder
            .addCase(register.pending, (state)=>{
                state.isLoading = true
            })
            .addCase(register.fulfilled, (state, action)=>{
                state.isLoading = false
                state.isSuccess = true
                state.user = action.payload
            })
            .addCase(register.rejected, (state, action) =>{
                state.isError = true
                state.isLoading = false
                state.user = null
                state.message = action.payload
            })
            .addCase(login.pending, (state)=>{
                state.isLoading = true
            })
            .addCase(login.fulfilled, (state, action)=>{
                state.isLoading = false
                state.isSuccess = true
                state.user = action.payload
            })
            .addCase(login.rejected, (state, action) =>{
                state.isError = true
                state.isLoading = false
                state.user = null
                state.message = action.payload
            })
            .addCase(logout.fulfilled, (state)=>{
                state.user = null
            })
    }
})

export const { reset} = authSlice.actions
export default authSlice.reducer