import { atom, selector } from "recoil";
import { recoilPersist} from 'recoil-persist'

const { persistAtom } = recoilPersist()

const sidebarAtom = atom({
    key: 'sidebarAtom',
    default: false,
    effects_UNSTABLE:[persistAtom]
  });

  const adminSidebarAtom = atom({
    key: 'adminSidebarAtom',
    default: false,
    effects_UNSTABLE:[persistAtom]
  });

 

  export { sidebarAtom, adminSidebarAtom }