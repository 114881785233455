const Empty = ({icon, title, details, type}) => {
    return (
      <div className={`empty ${type=== 'mini' ? 'empty-mini' : ''}`}>
      <div className={`empty-wrapper ${type=== 'mini' ? 'empty-wrapper-sm' : ''}`}>
        {icon}
      </div>
      <span className='main-message'>{title}</span>
      <span className='detail-message'>{details}</span>
    </div>
    )
  }
  
  export default Empty