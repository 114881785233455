import React from 'react'
import Footer from '../../component/Footer'
import ClientContent from '../admincomponent/ClientContent'

const Clients = () => {
  return (
   <>
    <ClientContent />
    <Footer />
   </>
  )
}

export default Clients