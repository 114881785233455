const FormTextareaComponent = ({ textName, value, onChange, textLabel }) => {
  // @function : return list to the select item

  return (
    <div className="form-group input-wrapper">
     
      <textarea
      className="form-control"
        name={textName}
        cols="30"
        rows="3"
        placeholder={textLabel}
        value={value}
        onChange={onChange}
      ></textarea>
    </div>
  );
};

export default FormTextareaComponent;
