import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate} from "react-router-dom"
import Forminputgroup from '../../component/reusables/Forminputgroup'
import PasswordInput from '../../component/reusables/PasswordInput'
import { ImSpinner3 } from "react-icons/im";
import { toast } from "react-toastify";
import logo from "../../assets/img/Zealand.png";
import loginImg from "../../assets/img/login-concept-illustration_114360-739.avif";
import {  reset } from "../../features/auth/authSlice";
import {  userLogin } from "../../util/services";
import { HiArrowNarrowRight } from "react-icons/hi";
import { FaEnvelope, FaEnvelopeOpen } from "react-icons/fa";

const Login = () => {
  const [loginData, setLogindata] = useState({
    email:'',
    password:''
  })
      const [toggle, setToggle] = useState(false)
      const [isLoading, setIsloagin] = useState(false)

      const { user, isError, isSuccess, message } = useSelector(
        (state) => state.auth
      );

      const dispatch = useDispatch();
      const navigate = useNavigate();
      useEffect(() => {
        // dispaly toast message when error
        if (isError) {
          toast.error(message);
        }
        //redirect to dashboard, when successful
        if (isSuccess) {
          toast.success("Login successfully!");
          navigate('/user/dashboard')
        }
        dispatch(reset());
      }, [isError, isSuccess, message, user, navigate, dispatch]);

  const handleToggele = () =>{
    setToggle(!toggle)
  }

  const handleLoginchage = (e) =>{
    setLogindata({...loginData, [e.target.name]:e.target.value})
  }
 
  const login = async (e) =>{
    e.preventDefault()
    try {
      setIsloagin(true)
      const { data } = await userLogin(loginData)
  
      if(data.success){
        toast.success(data.message)
        localStorage.setItem('user', JSON.stringify(data.data))
        navigate('/user/dashboard');
      }
      setIsloagin(false)
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
      toast.error(message)
      setIsloagin(false)
    }
  }
  return (
    <section className="section-sm ">
      
    <div className="container px-4 new-style ">
  
      <Link className="navbar-brand logo-wraps" to={`/`}>
                <img className="" src={logo} alt="zealand logo" />
              </Link>
      <div className="row page-wrapper bg-light-2">
              <div className="col-lg-6 left"> 
                  <img src={loginImg} alt="" />
               </div>
               
              <div className="col-lg-6 right">
                  <h4 className="real-title">Forget Password</h4>  
                  <p className="more-details">Please provide your email address</p>

                  <Forminputgroup
                      inputLabel={`Email Address`}
                      inputName="email"
                      inputType={`email`}
                      inputRequire={true}
                      value={loginData.email}
                      onChange={handleLoginchage}
                />

            
        
        <button  className="btn btn-success" onClick={login}>
              
              <span> Send Message {" "}</span>
               
               {isLoading ? <ImSpinner3 className="icon-spin" /> :  <FaEnvelope /> }
             </button>
      


          <div className="form-footer">
                 
                  <p className="txt-center">
                    Remember your password ?{" "}
                    <Link className="forget-pass" to={`/login`}>
                      {" "}
                      Login {" "}
                    </Link>{" "}
                    here.
                  </p>
                </div>

              </div> 
      </div>
    </div>
  </section>
  )
}

export default Login