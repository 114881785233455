import React from 'react'
import Footer from '../../component/Footer'
import DashboardContent from '../admincomponent/DashboardContent'

const AdminDashboard = () => {
  return (
    <>
    <DashboardContent />
    <Footer />

    </>
  )
}

export default AdminDashboard