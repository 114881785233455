import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import dateFormat from "dateformat";
import Sidebar from './Sidebar'
import { NumericFormat } from 'react-number-format'
import { toast } from "react-toastify";
import { ImSpinner3 } from "react-icons/im";
import {Modal, Button} from 'react-bootstrap'
import DOMPurify from 'dompurify';
import Forminputshort from '../../component/reusables/Forminputshort';
import FormTextareaComponent from '../../component/reusables/FormTextareaComponent';
import { getPercentage, getSubstring, imgList } from '../../util/helpers'
import AppTopBar from '../../component/AppTopBar'
import { FaEye, FaTrashAlt, FaPlusCircle, FaRegSave, FaPenAlt, FaArrowAltCircleRight, FaTimes } from 'react-icons/fa'
import { createProperty, deleteProperty, getProperties, getProperty } from '../../features/property/propertySlice';
import DateInpute from '../../component/reusables/DateInpute'

import SelectConsultant from '../../component/reusables/SelectConsultant';
import { getClients } from '../../features/client/clientSlice';
import SelectClient from '../../component/reusables/SelectClient';
import { addProperty, getAllUsers, getProfile, saveBuyer } from '../../util/services';
import SelectReferals from '../../component/reusables/SelectReferals';
import FormatableTextArea from '../../component/reusables/FormatableTextArea';


const PropertyContent = () => {
const [isLoading, setIsloading] = useState(false)
const [show, setShow] = useState(false)
const [showAssign, setShowAssign] = useState(false)
const [showMore, setShowmore] = useState(false)
const [fileList, setFilelist] = useState(null);
const [loading, setLoading] = useState(false)

const [propertData, setPropaertydata] = useState({
    propertyName:'',
    actualPrice:'',
    promoPrice:'',
    promoDetails:'',
    location:'',
    cofo:'',
    features:'',
    landSize:'',
    surveyPrice:'',
    deedofAssignment:'',
    images:'',
    description:'',
    developmentalLevy:''
})
// const [editorHtml, setEditorHtml] = useState('');

  const handleTextChange = (html) => {
    setPropaertydata({...propertData, description:DOMPurify.sanitize(html)})
  };



const [asignData, setAssigndata] = useState({
    clientId:'',
    amountPaid:'',
    purchaseDate:'',
    description:'',
    directRef:'',
    firstRef:'',
    secondRef:'',
    propertyId:'',
    consultantId:''

})

const [directRefs, setDirectref] = useState({id:'',commision:'15',amount:''})
const [secondDirect, setSeconddirect] = useState({id:'',commision:'2.5',amount:''})
const [thirdRef, setThirdref] = useState({id:'',commision:'0.5',amount:''})


const {
  propertyName,
  actualPrice,
  promoPrice,
  promoDetails,
  location,
  cofo,
  features,
  landSize,
  surveyPrice,
  deedofAssignment,
  images,
  description,
  developmentalLevy
} = propertData
const handleChanges = (e) =>{
    setAssigndata({...asignData, [e.target.name]:e.target.value})
}
const handleClose = () => setShow(false)

const closeAssign = () => setShowAssign(false)

const closeMore = () => setShowmore(false)

const handleImageChange = (e) => {
    setPropaertydata({ ...propertData, [e.target.name]: e.target.files[0] });
  };

  // const handleMultipleImageChange = (e) => {
  //   setFilelist(e.target.files);
  // };

const handleChange =(e) =>{
    setPropaertydata({...propertData, [e.target.name]:e.target.value})
}

const files = fileList ? [...fileList] : [];

const [propertyData, setPropertydata] = useState([])
const [userList, setUserlist] = useState([])
const [clientList, setClientlist] = useState([])
const [firstRef, setFirstref] = useState(null)
const [isAdding, setIsadding] = useState(false)
const [secondRefs, setSecondRef] = useState(null)

const { isLoadingP, isErrorP,added, isFetchSuccessP,isSuccessP, property, properties, messageP } =
useSelector((state) => state.property);

const { isLoadingU, isErrorU, isFetchSuccessU,isSuccessU, profile, users, messageU } =
useSelector((state) => state.user);

const { isLoadingC, isErrorC, isFetchSuccessC,isSuccessC, client, clients,resp, messageC } =
useSelector((state) => state.client);

const dispatch = useDispatch()

useEffect(() => {
  if (isErrorP) {
    toast.error(messageP);
  }
  dispatch(getProperties());
  if (isFetchSuccessP) {
    setPropertydata(properties.data);
  }
 
 
}, [isFetchSuccessP, propertyData]);



useEffect(() => {
  if (isErrorU) {
    toast.error(messageU);
  }
   
}, [isFetchSuccessU, userList]);

useEffect(() => {
  if (isErrorC) {
    toast.error(messageC);
  }
  dispatch(getClients());
  if (isFetchSuccessC) {
    setClientlist(clients.data);
  }
  fetchUser()
}, [isFetchSuccessC, clientList]);


let formData = new FormData();

  formData.append("propertyName", propertyName);
  formData.append("description", description);
  formData.append("actualPrice", actualPrice);
  formData.append("promoPrice", promoPrice);
  formData.append("surveyPrice", surveyPrice);
  formData.append("location", location);
  formData.append("deedofAssignment", deedofAssignment);
  formData.append("promoDetails", promoDetails);
  formData.append("cofo", cofo);
  formData.append("developmentalLevy", developmentalLevy);
  formData.append("features", features);
  formData.append("images", images);
  
  // files.forEach((file, i) => {
  //   formData.append(`supportingImages[${i}]`, file, file.name);
  // });

const handleDelete = (id) =>{
  if(window.confirm("Do you want to delete this property?")){
    if(dispatch(deleteProperty(id))){
      toast.success("Property deleted successfully")
      const updatedRecords = propertyData.filter((property) => property.id !== id);
      setPropertydata( updatedRecords)
  }
   
   
  }
    
}



const showProperty = (id) =>{
dispatch(getProperty(id))
}


  const renderData = () => {
    if (propertyData && propertyData.length > 0) {
      return propertyData.map((item) => {
        return (
          <tr key={item.id}>
            <td style={{ width:'100px' }} >{item.property_name}</td>
            <td>{item.location}</td>
            <td>
       
            <span dangerouslySetInnerHTML={{__html:getSubstring(item.description)}}></span>
            </td>
            <td><NumericFormat value={item.actual_price} thousandSeparator={true} className="format-number" /></td>
            <td><NumericFormat value={item.promo_price} thousandSeparator={true} className="format-number" /></td>
            <td><NumericFormat value={item.survey_price} thousandSeparator={true} className="format-number" /></td>  
            <td><NumericFormat value={item.developmental_levy} thousandSeparator={true} className="format-number" /></td>  
            <td>{item.c_of_o}</td>  
            <td>{item.deed_of_assignment}</td> 
            <td style={{width:'80px'}}>
            <FaTrashAlt className="delete" onClick={() => handleDelete(item.id)}  />{" "}
            <FaEye className="details" onClick={() =>{
              showProperty(item.id)
              setShowmore(true)
            }}/> 
                         
              </td>
              <td><button className='customeBtn custom-secondary'  onClick={() => {
                setAssigndata({...asignData, propertyId:item.id})
                setShowAssign(true)
              }}><FaPenAlt /> Assign commision</button> 
                </td>
          </tr>
        );
      });
    } else {
      <div className="alert alert-primary"> No item found</div>;
    }
  };



// Add property
const handleSubmitPropert = async (e) =>{
  e.preventDefault()
  try {
    setIsadding(true)
    const response = await addProperty(formData)
   
    if(response.status === 200){
      toast.success("New property has been added!")
      addRecord(response.data?.property)
      setShow(false)
    }
    setIsadding(false)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
		toast.error(message)
    setIsadding(false)
  }

}


//submit commision
const handleSubmit = async (e) =>{
  e.preventDefault()
  setLoading(true)
  // console.log(asignData);
  try {
    const { data } = await saveBuyer(asignData)
    if(data !== null){
      setLoading(false)
    }
  } catch (error) {
    console.log(error);
  }
}

const handleClientChange = (e) => {
  
  setAssigndata({...asignData, clientId:e.target.value})
}
const handleConsultantChange = (e) =>{
  fetchFirstref(e.target.value)

  let comision = getPercentage(asignData.amountPaid, directRefs.commision)
  setDirectref({...directRefs, amount:comision, id:e.target.value})
}

const handleFirstrefChange = (e) =>{
  fetchSecondref(e.target.value)
  let comision = getPercentage(asignData.amountPaid, secondDirect.commision)
  setSeconddirect({...secondDirect, amount:comision, id:e.target.value})
  setAssigndata({...asignData, consultantId:e.target.value})
}


const fetchUser = async() =>{
	try {
		setLoading(true)
		const { data } = await getAllUsers()
	
		setUserlist(data.data)
		setLoading(false)
	} catch (error) {
		const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
		toast.error(message)
	}
}

const addRecord = (record) => {
  setPropertydata([record, ...propertyData]);
};

const handleThirdrefChange = (e) =>{
 
  let comision = getPercentage(asignData.amountPaid, thirdRef.commision)
  setThirdref({...thirdRef, amount:comision, id:e.target.value})
}

const fetchFirstref = async (id) => {
  try {
    const { data } = await getProfile(id);
   
    setFirstref(data.referals);
 
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    toast.error(message)
  }
};

const fetchSecondref = async (id) => {
  try {
    const { data } = await getProfile(id);
    setFirstref(data.referals);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    toast.error(message)
  }
};

const onMouseEnter = () =>{
  setAssigndata({...asignData, directRef:JSON.stringify(directRefs), firstRef:JSON.stringify(secondDirect), secondRef:JSON.stringify(thirdRef)})
}



  return (
    <>
    <div className="inner-page-wrapper">
			
				{/* Sidebar */}
                <Sidebar />
			<div id="content-wrapper" className="content-wrapper d-flex flex-column">

				{/* Main Content  */}
				<div id="content">

					 {/* Topbar */}

				<AppTopBar />

					 {/* Begin Page Content  */}
					<div className="container-fluid">

						{/* Page Heading  */}
						<div className="d-sm-flex align-items-center justify-content-between mb-2">
							
						<div className="page-breadcrumbs">
							<nav aria-label="breadcrumb">
							<ol className="breadcrumb justify-content-center text-center">
								<li className="breadcrumb-item ">Dashboard</li>
								<li className="breadcrumb-item active" aria-current="page">
								 Properties
								</li>
							</ol>
							</nav>
              			</div>
						</div>

						
        <div className="row  mt-4 main-wrap">
        <div className="card shadow mb-5">
				

                <div className="card-header py-3 top">
                <h6 className="m-0 font-weight-bold text-primary">Properties</h6>
                <div> <button className="btn btn-primary" onClick={() => setShow(true)}> <FaPlusCircle /> Add Property </button></div>
              </div>
{
  isLoadingP ? <>Loading....</> :
  <>
  <div className="card-body table-responsive">
			<table className='table table-stripped table-bordered dataTable custom-table'>
				<thead>
					<tr>
						<th className='col-width-100'>Property Name</th>
						<th style={{ width:'100px' }}>Location</th>
						<th style={{ width:'100px' }}>Description</th>
						<th>Actual Price</th>
            <th>Promo Price</th>
            <th>Survey Price</th>
            <th>Developmental Levy</th>
            <th>C of O</th>
            <th>Deed of Assignment</th>
						<th>Action</th>
            <th>Commission</th>
					</tr>
				</thead>
				<tbody>
					{renderData()}
				</tbody>
			</table>
            </div>
  </>
}
				


            </div>
	
        </div>
					
	</div>
	</div>
	</div>
</div>

<Modal show={show} onHide={handleClose} size="lg">
<Modal.Header closeButton>
    <Modal.Title><FaPlusCircle /> Add Property</Modal.Title>
</Modal.Header>
<Modal.Body>
        <div className="form-group row">
        <Forminputshort
            inputLabel={`Property Name`}
            inputName="propertyName"
            inputType={`text`}
            inputRequired={true}
            value={propertData.propertyName}
            onChange={handleChange}
        />
         <Forminputshort
            inputLabel={`Property Location`}
            inputName="location"
            inputType={`text`}
            inputRequired={true}
            value={propertData.location}
            onChange={handleChange}
        />
        </div>

        <div className="form-group row">
        <Forminputshort
            inputLabel={`Actual Price`}
            inputName="actualPrice"
            inputType={`number`}
            inputRequired={true}
            value={propertData.actualPrice}
            onChange={handleChange}
        />
         <Forminputshort
            inputLabel={`Promo Price`}
            inputName="promoPrice"
            inputType={`number`}
            inputRequired={false}
            value={propertData.promoPrice}
            onChange={handleChange}
        />
        </div>

        
        <div className="form-group row">
        <Forminputshort
            inputLabel={`Deed of Assignment`}
            inputName="deedofAssignment"
            inputType={`text`}
            inputRequired={true}
            value={propertData.deedofAssignment}
            onChange={handleChange}
        />
         <Forminputshort
            inputLabel={`C of O`}
            inputName="cofo"
            inputType={`text`}
            inputRequired={false}
            value={propertData.cofo}
            onChange={handleChange}
        />
        </div>

        
        <div className="form-group row">
        <Forminputshort
            inputLabel={`Features`}
            inputName="features"
            inputType={`text`}
            inputRequired={false}
            value={propertData.features}
            onChange={handleChange}
        />
         <DateInpute
            inputLabel={`Promo Ends Date`}
            inputName="promoDetails"
            
            inputRequired={false}
            value={propertData.promoDetails}
            onChange={handleChange}
            // onFocus={onFocus}
            inputType={'text'}
        />
        </div>

        
        <div className="form-group row">
        <Forminputshort
            inputLabel={`Survey Price`}
            inputName="surveyPrice"
            inputType={`number`}
            inputRequired={false}
            value={propertData.surveyPrice}
            onChange={handleChange}
        />
         <Forminputshort
            inputLabel={`Land Size`}
            inputName="landSize"
            inputType={`text`}
            inputRequired={false}
            value={propertData.landSize}
            onChange={handleChange}
        />
        </div>
        <div className="form-group row">
        <Forminputshort
            inputLabel={`Developmental Levy`}
            inputName="developmentalLevy"
            inputType={`number`}
            inputRequired={false}
            value={propertData.developmentalLevy}
            onChange={handleChange}
        />
        
        </div>

        <div className="form-group input-wrapper">
          

          <FormatableTextArea value={propertData.description} handleChange={handleTextChange} className='form-control'  />
        </div>

        <div className="form-group row">
                    <div className="form-group input-wrapper col-lg-6">
                      <label htmlFor="" className="input-label">
                        Property Images <small className="file-info">(File format: PNG,JPG,JPEG) and Max size is 2mb</small>
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name="images"
                        onChange={handleImageChange}
                
                      />
                    </div>

                    {/* <div className="form-group input-wrapper col-lg-6">
                      <label htmlFor="" className="input-label">
                        Property Document <small className="file-info">(File format: PDF, PNG,JPG,JPEG) and Max size is 2mb</small>
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name="propertyDocument"
                        onChange={handleImageChange}
                      />
                    </div> */}
                  </div>
               
</Modal.Body>
<Modal.Footer>

<Button variant="secondary" onClick={handleSubmitPropert}>
      {
        isAdding ? <><ImSpinner3 className="icon-spins" /></>: <><FaRegSave /> Save</>
      }
       
    </Button>
    <Button variant="primary" onClick={handleClose}>
      
        <FaTimes /> Close
      
       
    </Button>

    

</Modal.Footer>
</Modal>

{/* Assign Commision */}

<Modal show={showAssign} onHide={closeAssign} size="lg">
<Modal.Header closeButton>
    <Modal.Title><FaPlusCircle /> Assign Commision</Modal.Title>
</Modal.Header>
<Modal.Body>
       
<div className="form-group row">
          <SelectClient
                      selectName="clientId"
                      selectOption ={`Select Client`}
                      itemList={clientList}
                      onChange={handleClientChange}
                      value={asignData.client}
                    />
</div>
<div className="form-group row">
        <Forminputshort
            inputLabel={`Amount Paid`}
            inputName="amountPaid"
            inputType={`number`}
            inputRequired={true}
            value={asignData.amountPaid}
            onChange={handleChanges}
        />
         <Forminputshort
            inputLabel={`Purchase date`}
            inputName="purchaseDate"
            inputType={`date`}
            inputRequired={true}
            value={asignData.purchaseDate}
            onChange={handleChanges}
        />
</div>  

<FormTextareaComponent 
            textName={`description`}
            value={asignData.description}
            textLabel={`Description(optional)`}
            onChange={handleChanges}
             />
        
<hr />
<h5>Setup Commition %</h5>
<div className="form-group row">
  
        <SelectConsultant
                      selectName="consultant"
                      selectOption ={`Direct referral`}
                      itemList={userList}
                      onChange={handleConsultantChange}
                      
                    />
        
         <Forminputshort
            inputLabel={`1st Referer (15%)`}
            inputName="firstPercent"
            inputType={`number`}
            inputRequired={true}
            onChange={handleChange}
            value={directRefs.amount}
        />
</div>
<div className="form-group row">
  
<SelectReferals
                      selectName="consultant"
                      selectOption ={`2nd referral`}
                      itemList={firstRef}
                      onChange={handleFirstrefChange}  
                    />
         <Forminputshort
            inputLabel={`2nd Referer (2.5%)`}
            inputName="firstPercent"
            inputType={`number`}
            inputRequired={true}
            value={secondDirect.amount}
            onChange={handleChange}
        />
</div>
<div className="form-group row">
  
        <SelectReferals
                      selectName="consultant"
                     
                      selectOption ={`3rd referral`}
                      itemList={secondRefs}
                      onChange={handleThirdrefChange}
                    />
         <Forminputshort
            inputLabel={`3rd Referer (0.5%)`}
            inputName="firstPercent"
            inputType={`number`}
            inputRequired={true}
            value={thirdRef.amount}
            onChange={handleChange}
        />
</div> 

               
</Modal.Body>
<Modal.Footer>

<Button variant="secondary" onClick={handleSubmit} onMouseEnter={onMouseEnter}>
      {
        loading ? <><ImSpinner3 className="icon-spins" /></>: <><FaArrowAltCircleRight /> Proceed</>
      }
       
    </Button>


</Modal.Footer>
</Modal>

{/* Property details */}
<Modal show={showMore} onHide={closeMore} size="lg">
<Modal.Header closeButton>
    <Modal.Title><FaPlusCircle /> Property Details</Modal.Title>
</Modal.Header>
<Modal.Body>
  <div className="property-img">
      <img src={property !== null ? `https://api.zealandproperties.com/images/${property.property.property_images}` : ""} 
           alt={property !== null ? property.property.property_name : ""}
       />


  </div>
	<div className="form-group row">
		<div className="col-lg-6">
			<strong>Property Name: </strong> {property !== null ? property.property.property_name : ""}
		</div>
		<div className="col-lg-6">
			<strong>Location: </strong> {property !== null ? property.property.location : ""}
		</div>
	</div>
	<div className="form-group row mt-1">
		<div className="col-lg-6">
			<strong>Actual Price: </strong> &#x20A6; {property !== null ? <NumericFormat className="format-number" value={property.property.actual_price} thousandSeparator={true} /> : ""}
		</div>
		<div className="col-lg-6">
			<strong>Promo Price: </strong> &#x20A6; {property !== null ? <NumericFormat className="format-number" value={property.property.promo_price} thousandSeparator={true} /> : ""}
		</div>
	</div>

	<div className="form-group row mt-1">
		<div className="col-lg-6">
			<strong>Promo Details: </strong> {property !== null ? property.property.promo_details : ""}
		</div>
		<div className="col-lg-6">
			<strong>Survey Price: </strong>&#x20A6;  {property !== null ? <NumericFormat className="format-number" value={property.property.survey_price} thousandSeparator={true} /> : ""}
		</div>
	</div>

	<div className="form-group row mt-1">
		<div className="col-lg-6">
			<strong>C of O / Deed of Assignment: </strong> {property !== null ? `${property.property.c_of_o}/${property.property.deed_of_assignment}`
 : ""}
		</div>
		<div className="col-lg-6">
			<strong>Date Registered: </strong> {property !== null ? 
			 
			 dateFormat( property.property.dateCreated, "dddd, mmmm dS, yyyy, h:MM:ss TT")
			 : ""}
		</div>
	</div>
	<div className="form-group description">
  <strong>Property Description: </strong> <span dangerouslySetInnerHTML={{__html:property !== null ? property.property.description
			 : ""}}></span>
  </div>
 
</Modal.Body>
<Modal.Footer>



    <Button variant="primary" onClick={closeMore}>
      <><FaTimes /> Close</>
    </Button>

</Modal.Footer>
</Modal>

</>
  )
}

export default PropertyContent