import {useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Sidebar from './Sidebar'
import { NumericFormat } from 'react-number-format'

import {Modal, Button} from 'react-bootstrap'
import UserTopbar from '../../component/UserTopbar'
import { FaInfoCircle, FaTimes, FaPlusCircle, FaMoneyBillWaveAlt, FaHeart, FaUserFriends, FaHandsHelping, FaBullseye } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { getProperties, getProperty } from '../../features/property/propertySlice'

import dateFormat from 'dateformat'
import { imgList } from '../../util/helpers'
import { FiCopy, FiHome,  FiUsers } from 'react-icons/fi'
import Empty from '../../component/Empty'
import { ImFileEmpty } from 'react-icons/im'
import { getUserprofile } from '../../util/services'
import { Link } from 'react-router-dom'
import {  getUser } from '../../util/services';


const UserDashboard = () => {

const user = JSON.parse(localStorage.getItem("user"));

const {  isErrorP, isFetchSuccessP, properties,property, messageP } =
useSelector((state) => state.property);

const dispatch = useDispatch()
const [propertyData, setPropertydata] = useState([])
const [showMore, setShowmore] = useState(false)

const [profile, setProfile] = useState(null)
const [referals, setReferal] = useState([])
const [author, setAuthor] = useState(null)
const [loading, setIsloading] = useState(false)


useEffect(() => {
	getProfile(user.id)
	if (isErrorP) {
	  toast.error(messageP);
	}
	showUser(user.id)
	dispatch(getProperties());
	if (isFetchSuccessP) {
	  setPropertydata(properties.data);
	}
  }, [isFetchSuccessP, propertyData]);

  const getSomeElement = (arr) =>{
	if (arr && arr.length > 0){
		return arr.slice(0,4)
	}
  }


  const showUser = async(id) =>{
	try {
		setIsloading(true)
		const { data} = await getUser(id)
	
		if(data.success){
			setProfile(data.data)
			setReferal(data.referals)
			setAuthor(data.author)
		}
		setIsloading(false)
	} catch (error) {
		const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        toast.error(message)
	}
  }

  const getProfile = async(id) =>{
	const { data }= await getUserprofile(id)
	
	setProfile(data.data)
  }
  
 
  
  const referraList = () => {
    if (referals !== null && referals.length > 0) {
      return referals.map((item) => {
        return (
          <tr key={item.id}>
      <td>{dateFormat(item.dateRegistered, "dddd, mmmm dS, yyyy")}</td>
            <td>{item.first_name + " "+ item.last_name}</td>
      <td>{item.email}</td>
            <td>{item.phone_number}</td> 
          </tr>
        );
      });
    } else {
      <div className="alert alert-primary"> No item found</div>;
    }
  };

  const closeMore = () => setShowmore(false)
  const displayItem = getSomeElement(propertyData)
  const showProperty = (id) =>{
	dispatch(getProperty(id))
	}

  const renderData = () => {
    if (displayItem && displayItem.length > 0) {
      return displayItem.map((item) => {
		let images = "" 					//JSON.parse(item.supporting_images)
		let displayImg = images && images.length > 0 ? images[0] : ""
        return (
			<div className="col-lg-3 animate__animated animate__fadeInDown animate__delay-a">
			<div className="content-box justify-content-center text-center">
				<div className="image-wrapper">
					<img src={`https://api.zealandproperties.com/properties/${displayImg}`} alt={item.propertyName} />
				</div>
			
				<div className="box-content">
					<h4 className="box-title"><a href="#">{item.propertyName} <small> {item.location} </small></a></h4>
					<p className="box-desc">{item.description}</p>
				</div>
				<p className='price'>Promo Price: <NumericFormat value={item.promoPrice} thousandSeparator={true} className="format-number"/> </p>
				<p className='price'>Actual Price: <NumericFormat value={item.actualPrice} thousandSeparator={true} className="format-number"/> </p>
				<div className="box-bottom">
					<a className="btn btn-primary btn-round btn-shadow btn-sm" onClick={() =>{
						showProperty(item.id)
						setShowmore(true)
					}}>View Details</a>
				</div>
			</div>
		</div>
        );
      });
    } else {
      <div className="alert alert-primary"> No item found</div>;
    }
  };
  
 
  return (
	<>
    <div className="inner-page-wrapper">
			
				{/* Sidebar */}
                <Sidebar />
			<div id="content-wrapper" className="content-wrapper d-flex flex-column">

				{/* Main Content  */}
				<div id="content">

					 {/* Topbar */}
					 {/* data={data}  */}
				<UserTopbar />

					 {/* Begin Page Content  */}
					<div className="container-fluid">

					{
						profile !== null && profile.dob !== null ? 
						<></>
						:
						<p className='alert alert-danger alert-dismissible show mt-3 password-format text-left'><strong><FaInfoCircle /> </strong> <strong>Hi!</strong> Welcome to Zealand properties affiliate platform, your profile is not completed yet, click <Link to={`/user/profile`} className='forgot-pass'>Here</Link> to complete your profile <br/>
					
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </p>
					}

								{
									profile !== null && profile.bank.length > 0 ?
									<></>
									:
									<p className='alert alert-warning alert-dismissible fade show mt-3 password-format text-left'><strong><FaInfoCircle /> </strong> <strong>Hi  !</strong> Note that to get your benefit, you need to add your bank details, kindly click <Link to={`/user/bank`} className='forgot-pass'>HERE</Link> to add your bank details <br/>
					
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </p>
								}
						
						 <div className="row top-menu-list">
							<div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 menu-card mr-4">
								<div className="left">
								<span className='menu-title'>Referals</span>
								<span className='menu-figure'>{referals.length || 0}</span>
								<span className='menu-sub'> Registered users</span>
								</div>
								<div className="right">
									<FiUsers className='menu-icon' />
								</div>
							</div>
							
							
							<div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 menu-card mr-4">
								<div className="left">
								<span className='menu-title'>Properties</span>
								<span className='menu-figure property'>{propertyData.length || 0}</span>
								<span className='menu-sub'>Available properties</span>
								</div>
								<div className="right">
									<FiHome className='menu-icon property' />
								</div>
							</div>

							<div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 menu-card mr-4">
								<div className="left">
								<span className='menu-title'>Earnings</span>
								<span className='menu-figure client'>{ 0}</span>
								<span className='menu-sub'>Registered clients</span>
								</div>
								<div className="right">
									<FaMoneyBillWaveAlt className='menu-icon client' />
								</div>
							</div>

						</div>  




<p className='alert alert-secondary alert-dismissible fade show mt-3 password-format text-left'><strong><FaInfoCircle /> </strong> <strong>Your Unique Referral Link</strong> {`https://portal.zealandproperties.com/register/${user.referal_code}`} <br/>
		<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</p>

<div className="section-wrapper">
	<div className="title">Your Referals</div>
	<div className="section-contanet">
		{
  referals !== null && referals.length > 0 ?
  <table className='table table-stripped table-bordered dataTable'>
  <thead>
    <tr>
    <th>Date Registered</th>
      <th>Full Name</th>
      <th>Email</th>
      <th>Phone No</th>
    </tr>
  </thead>
  <tbody>
    {referraList()}
    
    
  </tbody>
</table>
:
<Empty title="No referal record" details="You have not refer anyone." icon={<ImFileEmpty className='empty-icon' />} />
}

	</div>
</div>
	


							
		
					
					</div>
				</div>
			</div>
		</div>


{/* Property details */}
<Modal show={showMore} onHide={closeMore} size="lg">
<Modal.Header closeButton>
    <Modal.Title><FaPlusCircle /> Property Details</Modal.Title>
</Modal.Header>
<Modal.Body>
  <div className="property-img">
  <img src={property !== null ? `https://api.zealandproperties.com/images/${property.property.property_images}` : ""} 
           alt={property !== null ? property.property.property_name : ""}
       />

  </div>
	<div className="form-group row">
		<div className="col-lg-6">
			<strong>Property Name: </strong> {property !== null ? property.property.propertyName : ""}
		</div>
		<div className="col-lg-6">
			<strong>Location: </strong> {property !== null ? property.property.location : ""}
		</div>
	</div>
	<div className="form-group row mt-1">
		<div className="col-lg-6">
			<strong>Actual Price: </strong> &#x20A6; {property !== null ? <NumericFormat className="format-number" value={property.property.actualPrice} thousandSeparator={true} /> : ""}
		</div>
		<div className="col-lg-6">
			<strong>Promo Price: </strong> &#x20A6; {property !== null ? <NumericFormat className="format-number" value={property.property.promoPrice} thousandSeparator={true} /> : ""}
		</div>
	</div>

	<div className="form-group row mt-1">
		<div className="col-lg-6">
			<strong>Promo Details: </strong> {property !== null ? property.property.promoDetails : ""}
		</div>
		<div className="col-lg-6">
			<strong>Survey Price: </strong>&#x20A6;  {property !== null ? <NumericFormat className="format-number" value={property.property.surveyPrice} thousandSeparator={true} /> : ""}
		</div>
	</div>

	<div className="form-group row mt-1">
		<div className="col-lg-6">
			<strong>C of O / Deed of Assignment: </strong> {property !== null ? `${property.property.cofo}/${property.property.deedofAssignment}`
 : ""}
		</div>
		<div className="col-lg-6">
			<strong>Date Registered: </strong> {property !== null ? 
			 
			 dateFormat( property.property.dateCreated, "dddd, mmmm dS, yyyy, h:MM:ss TT")
			 : ""}
		</div>
	</div>
	<div className="form-group description">
  <strong>Property Description: </strong> {property !== null ? property.property.description
			 : ""}
  </div>
        
</Modal.Body>
<Modal.Footer>



    <Button variant="primary" onClick={closeMore}>
      <><FaTimes /> Close</>
    </Button>

</Modal.Footer>
</Modal>
		</>
  )
}

export default UserDashboard