import React from 'react'

const DateInpute = ({inputName, inputLabel, inputType, inputRequired, value, onChange, disable, onFocus}) => {
  const checkRequired = ()=>{
    return inputRequired && ('*')
  }
  return (
    <div className="col-lg-6 input-wrapper">
    
    <input type={inputType} 
    onFocus={onFocus}
    className="form-control" name={inputName} 
    id={inputName} autoComplete="off" 
    placeholder={`${inputLabel} ${checkRequired()}`} required={inputRequired} value={value} onChange={onChange} 
    disabled={disable } />
</div>
  )
}

export default DateInpute
