
import { useRecoilState } from "recoil";
import img from "../assets/img/undraw_profile.svg";
import { Link, useNavigate } from "react-router-dom";
import { adminSidebarAtom } from "../user/atom/userAtom";
import { FiList } from "react-icons/fi";


const AppTopBar = () => {
const { data } = JSON.parse(localStorage.getItem("user"))
const [toggleNav, setToggleNav] = useRecoilState(adminSidebarAtom)

console.log(toggleNav);

const handleToggle = () =>{
    setToggleNav(!toggleNav)
}
    const navigate = useNavigate()

    const onLogout = () =>{
        navigate("/admin")
    }
  return (
    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

    {/* <!-- Sidebar Toggle (Topbar) --> */}
    {/* <button id="" className="btn btn-link d-md-none rounded-circle mr-3">
        <i className="bi bi-list"></i>
    </button> */}
     <button id="" className={`btn btn-link d-md-none rounded-circle mr-3 ${toggleNav ? 'hideToggle' : ''}`} onClick={handleToggle}>
        <FiList size={20} />
    </button>

    {/* <!-- Topbar Search --> */}
    <form
        className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
        <div className="input-group">
            <input type="text" className="form-control bg-light-2 border-0 small" placeholder="Search for..."
                aria-label="Search" aria-describedby="basic-addon2" />
            <div className="input-group-append">
                <button className="btn btn-primary btn-search" type="button">
                    <i className="bi bi-search"></i>
                </button>
            </div>
        </div>
    </form>

    {/* <!-- Topbar Navbar --> */}
    <ul className="navbar-nav ml-auto">

        {/* <!-- Nav Item - Search Dropdown (Visible Only XS) --> */}
        <li className="nav-item dropdown no-arrow d-sm-none">
            <a className="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button"
                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="bi bi-search"></i>
            </a>
            {/* <!-- Dropdown - Messages --> */}
            <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
                aria-labelledby="searchDropdown">
                <form className="form-inline mr-auto w-100 navbar-search">
                    <div className="input-group">
                        <input type="text" className="form-control bg-light border-0 small"
                            placeholder="Search for..." aria-label="Search"
                            aria-describedby="basic-addon2" />
                        <div className="input-group-append">
                            <button className="btn btn-primary" type="button">
                                <i className="bi bi-search"></i>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </li>

        {/* <!-- Nav Item - Alerts --> */}
        <li className="nav-item dropdown no-arrow mx-1">
            <a className="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button"
                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="bi bi-bell-fill"></i>
                {/* <!-- Counter - Alerts --> */}
                <span className="badge badge-danger badge-counter">0</span>
            </a>
          
        </li>

        {/* <!-- Nav Item - Messages --> */}
        <li className="nav-item dropdown no-arrow mx-1">
            <a className="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button"
                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="bi bi-envelope-fill fa-fw"></i>
                {/* <!-- Counter - Messages --> */}
                <span className="badge badge-danger badge-counter">0</span>
            </a>
         
        </li>

        <div className="topbar-divider d-none d-sm-block"></div>

        {/* <!-- Nav Item - User Information --> */}
        <li className="nav-item dropdown no-arrow">
            <Link className="nav-link dropdown-toggle" to={`#`} id="userDropdown" role="button"
                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span className="mr-2 d-none d-lg-inline text-gray-600 small">{data !== null ? data.first_name+" "+data.last_name : ""}</span>
                <img className="img-profile rounded-circle"
                    src={img} />
            </Link>
            {/* <!-- Dropdown - User Information --> */}
            <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                aria-labelledby="userDropdown">
                <Link className="dropdown-item" to={`#`}>
                    <i className="bi bi-person-fill fa-sm fa-fw mr-2 text-gray-400"></i>
                    Profile
                </Link>
               
                <div className="dropdown-divider"></div>
                <Link to={`/admin`} onClick={onLogout} className="dropdown-item" data-toggle="modal" data-target="#logoutModal">
                    <i className="bi bi-box-arrow-right fa-sm fa-fw mr-2 text-gray-400"></i>
                    Logout
                </Link>
            </div>
        </li>
    </ul>
</nav>
  )
}

export default AppTopBar