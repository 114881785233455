import {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import Sidebar from './Sidebar'
import { NumericFormat } from 'react-number-format'
import dateFormat from 'dateformat'

import { toast } from 'react-toastify'
import AppTopBar from '../../component/AppTopBar'
import {  FaMoneyCheck,  FaUserFriends } from 'react-icons/fa'
import { FiUsers, FiHome } from 'react-icons/fi'
import { getProperties } from '../../features/property/propertySlice'
import { getStat, getUsers } from '../../features/user/userSlice'
import { getAllUsers } from '../../util/services'


const DashboardContent = () => {

const [propertys, setProperty] = useState([])
const [userList, setUserlist] = useState([])
const [loading, setLoading] = useState(false)
	const { isLoadingP, isErrorP, isFetchSuccessP, properties, messageP } =
useSelector((state) => state.property);

const { isLoadingU, isFetchSuccessU, users,stat,isErrorU, messageU } =
useSelector((state) => state.user);


const dispatch = useDispatch()

useEffect(() => {
  if (isErrorP) {
    toast.error(messageP);
  }
  dispatch(getProperties());
  if (isFetchSuccessP === true) {
    setProperty(properties.data);
  }
 
  
}, [isFetchSuccessP,isFetchSuccessU, propertys]);

useEffect(() => {
	if (isErrorU) {
	  toast.error(messageU);
	}
	dispatch(getStat());
	fetchUser()
  }, [isFetchSuccessP,isFetchSuccessU, propertys]);

const fetchUser = async() =>{
	try {
		setLoading(true)
		const { data } = await getAllUsers()
		
		setUserlist(data.data)
		setLoading(false)
	} catch (error) {
		const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
		toast.error(message)
	}
}

const renderData = () => {
    if (propertys && propertys.length > 0) {
      return propertys.map((item) => {
        return (
          <tr key={item.id}>
            <td style={{width:'250px'}}>{item.property_name}</td>
            <td>{item.location}</td>
         
            <td><NumericFormat prefix='NGN' value={item.actual_price} thousandSeparator={true} className="format-number" /></td>
            <td><NumericFormat prefix='NGN' value={item.promo_price} thousandSeparator={true} className="format-number" /></td>
             
            <td>
				<img className='p-image' src={`https://api.zealandproperties.com/images/${item.property_images}`} alt={item.property_name} />
			</td>  
           
           
          </tr>
        );
      });
    } else {
      <div className="alert alert-primary"> No item found</div>;
    }
  };

//   Render users
const newUser = userList.slice(0,5)
  const renderUser = () => {
    if (newUser && newUser.length > 0) {
      return newUser.map((item) => {
        return (
          <tr key={item.id}>
			<td>{dateFormat(item.dateRegistered)}</td>
            <td>{item.first_name + " "+ item.last_name}</td>
			<td>{item.email}</td>
            <td>{item.phone_number}</td>  
			<td>{item.referal_code}</td> 
          </tr>
        );
      });
    } else {
      <div className="alert alert-primary"> No item found</div>;
    }
  };

  return (
    <div className="inner-page-wrapper">
			
				{/* Sidebar */}
                <Sidebar />
			<div id="content-wrapper" className="content-wrapper d-flex flex-column">

				{/* Main Content  */}
				<div id="content">

					 {/* Topbar */}

				<AppTopBar />

					 {/* Begin Page Content  */}
					<div className="container-fluid">

						{/* Page Heading  */}
						<div className="d-sm-flex align-items-center justify-content-between mb-5">
							<div className="page-breadcrumbs">
								<nav aria-label="breadcrumb">
								<ol className="breadcrumb justify-content-center text-center">
									<li className="breadcrumb-item ">Dashboard</li>
									<li className="breadcrumb-item active" aria-current="page">
									Home
									</li>
								</ol>
								</nav>
							</div>
						</div>

						<div className="row top-menu-list">
							<div className="col-lg-2 menu-card mr-4">
								<div className="left">
								<span className='menu-title'>All Users</span>
								<span className='menu-figure'>{stat !== null ? stat.data.allUsers : 0}</span>
								<span className='menu-sub'> Registered users</span>
								</div>
								<div className="right">
									<FaUserFriends className='menu-icon' />
								</div>
							</div>
							
							
							<div className="col-lg-2 menu-card mr-4">
								<div className="left">
								<span className='menu-title'>Properties</span>
								<span className='menu-figure property'>{stat !== null ? stat.data.allProperties : 0}</span>
								<span className='menu-sub'>Available properties</span>
								</div>
								<div className="right">
									<FiHome className='menu-icon property' />
								</div>
							</div>

							<div className="col-lg-2 menu-card mr-4">
								<div className="left">
								<span className='menu-title'>Clients</span>
								<span className='menu-figure client'>{stat !== null ? stat.data.allClient : 0}</span>
								<span className='menu-sub'>Registered clients</span>
								</div>
								<div className="right">
									<FiUsers className='menu-icon client' />
								</div>
							</div>

							<div className="col-lg-2 menu-card mr-4">
								<div className="left">
								<span className='menu-title'>Payouts</span>
								<span className='menu-figure pay-out'>100</span>
								<span className='menu-sub'>10% Increase</span>
								</div>
								<div className="right">
									<FaMoneyCheck className='menu-icon pay-out' />
								</div>
							</div>
							
						</div> 
						
<div className="row container mt-4 main-wrap">
	<div className="col-lg-6">
		
	<div className="card shadow mb-5">
		<div className="card-header py-3 top">
			<h6 className="m-0 font-weight-bold text-primary">Recent Properties</h6>
			<div> <Link className="btn btn-primary" to="/admin/properties">  View all </Link></div>
		</div>

		

		<div className="card-body table-responsive">
		{
					isLoadingP ? <>Loading...</> :
			<table className='table table-bordered dataTable'>
				<thead>
					<tr>
						<th style={{width:'250px'}}>Property Name </th>
						<th> Location </th>
						<th>Actual Price</th>
						<th>Promo Price</th>
						<th>Image</th>
					</tr>
				</thead>
				<tbody>
				
					{renderData()}
				
				</tbody>
			</table>
			}		
		</div>
		</div>

	</div>
	<div className="col-lg-6">
	
	<div className="card shadow mb-5">
				<div className="card-header py-3 top">
					<h6 className="m-0 font-weight-bold text-primary">Recent Users</h6>
					<div> <Link className="btn btn-primary" to="/admin/users">  View all </Link></div>
				</div>
				<div className="card-body table-responsive">
			{
				isLoadingU ? <>Loading....</> :
				<table className='table table-bordered dataTable'>
				<thead>
					<tr>
						<th>Date Registered</th>
						<th>Name</th>
						<th>Email</th>
						<th>Phone No</th>
						<th>Referer Code</th>
						
					</tr>
				</thead>
				<tbody>
					{renderUser()}
					
				</tbody>
			</table>
			}		
		</div>
				</div>
	</div>
</div>
					
					</div>
				</div>
			</div>
		</div>
  )
}

export default DashboardContent