import React from "react";

const Footer = () => {
  return (
    <footer className="section-xsm bg-light-2">
      <div className="footer-bottom">
        <div className="container px-4">
          <div className="row">
            <div className="small">Copyright &copy; 2023 - Zealand Properties</div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
