import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import service from './userService'


const initialState = {
    profile: null,
    users: null,
    stat: null,
    isErrorU: false,
    isSuccessU:false,
    isFetchSuccessU: false,
    isDeleteU:false,
    isDeletingU:false,
    isLoadingU:false,
    messageU:''
}

//@function: create director record
export const createUser = createAsyncThunk('user/create', async (inputData, thunkAPI) =>{
  
    try {
        const { token } = JSON.parse(localStorage.getItem("user"));
        return await service.createUser(inputData, token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

//@function: create individual record
export const getUsers = createAsyncThunk('users/fetch', async (page,thunkAPI) =>{
    try {
        
        const { token } = JSON.parse(localStorage.getItem("user"));
        return await service.getUsers(token, page)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

export const getStat = createAsyncThunk('stat/fetch', async (_,thunkAPI) =>{
    try {
        
        const { token } = JSON.parse(localStorage.getItem("user"));
        return await service.getStat(token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})


//@function: create individual record
export const getUser = createAsyncThunk('user/fetch', async (id, thunkAPI) =>{
    try {
        const { token } = JSON.parse(localStorage.getItem("user"));
        return await service.getUser(id, token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

//@function: delete property
export const deleteUser = createAsyncThunk('user/delete', async (id, thunkAPI) =>{
    
    try {
        const { token } = JSON.parse(localStorage.getItem("user"));
        return await service.deleteUser(id, token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

//@function: delete bussiness
export const updateUser = createAsyncThunk('user/update', async (id, inputData, thunkAPI) =>{
    
    try {
        const { token } = JSON.parse(localStorage.getItem("user"));
        return await service.updateUser(id,inputData, token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

export const userSlice = createSlice({
    name:'user',
    initialState,
    reducers:{
        reset:(state) => {
            state.isErrorU = false
            state.isLoadingU = false
            state.isSuccessU = false
            state.isFetchSuccessU = false
            state.isDeleteU = false
            state.isDeletingU = false
            state.messageU = ""
            state.profile = null
            state.users = null
            state.stat = null
        }
    },
    extraReducers: (builder) =>{
        builder
            .addCase(createUser.pending, (state)=>{
                state.isLoadingU = true
            })
            .addCase(createUser.fulfilled, (state, action)=>{
                state.isLoadingU = false
                state.isSuccessU = true
                state.profile = action.payload
            })
            .addCase(createUser.rejected, (state, action) =>{
                state.isErrorU = true
                state.isLoadingU = false
                state.messageU= action.payload
              
            })
            .addCase(getUsers.pending, (state)=>{
                state.isLoadingU = true
            })
            .addCase(getUsers.fulfilled, (state, action)=>{
                state.isLoadingU = false
                state.isFetchSuccessU = true
                state.users = action.payload
            })
            .addCase(getUsers.rejected, (state, action) =>{
                state.isErrorU = true
                state.isLoadingU = false
                state.messageU = action.payload
              
            })

            .addCase(getStat.pending, (state)=>{
                state.isLoadingU = true
            })
            .addCase(getStat.fulfilled, (state, action)=>{
                state.isLoadingU = false
                state.isFetchSuccessU = true
                state.stat = action.payload
            })
            .addCase(getStat.rejected, (state, action) =>{
                state.isErrorU = true
                state.isLoadingU = false
                state.messageU = action.payload
              
            })

            .addCase(deleteUser.pending, (state)=>{
                state.isDeletingU = true
            })
            .addCase(deleteUser.fulfilled, (state, action)=>{
                state.isDeletingU = false
                state.isDeleteU = true
            })
            .addCase(deleteUser.rejected, (state, action) =>{
                state.isErrorU = true
                state.isDeletingU = false
                state.messageU = action.payload
            })
            .addCase(getUser.pending, (state)=>{
                state.isLoadingU = true
            })
            .addCase(getUser.fulfilled, (state, action)=>{
                state.isLoadingU = false
                state.isFetchSuccessU = true
                state.profile = action.payload
            })
            .addCase(getUser.rejected, (state, action) =>{
                state.isErrorU = true
                state.isLoadingU = false
                state.messageU = action.payload
              
            })
            .addCase(updateUser.pending, (state)=>{
                state.isLoadingU = true
            })
            .addCase(updateUser.fulfilled, (state, action)=>{
                state.isLoadingU = false
                state.isFetchSuccessU = true
                state.profile = action.payload
            })
            .addCase(updateUser.rejected, (state, action) =>{
                state.isErrorU = true
                state.isLoadingU = false
                state.messageU = action.payload
              
            })
    }
})

export const { reset} = userSlice.actions
export default userSlice.reducer