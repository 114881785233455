import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import { RecoilRoot } from 'recoil';
import './css/giving.css';
import './css/styles.css'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RecoilRoot>
         <App />
      </RecoilRoot>
      
    </Provider>
 
  </React.StrictMode>
);


