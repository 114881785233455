import {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'

import dateFormat from 'dateformat'

import Sidebar from '../usercomponent/Sidebar'
import UserTopBar from '../../component/UserTopbar'
import { toast } from 'react-toastify'
// import { getUser } from '../../features/user/userSlice'
import Empty from '../../component/Empty'
import { ImFileEmpty } from 'react-icons/im'
import {  getUser } from '../../util/services';


const Referals = () => {
  const [profile, setProfile] = useState(null)
	const [referals, setReferal] = useState([])
	const [author, setAuthor] = useState(null)
  const [loading, setIsloading] = useState(false)
    const user = JSON.parse(localStorage.getItem("user"));
   

    const {  isErrorU, isFetchSuccessU,  messageU } = useSelector((state) => state.user);


useEffect(() => {
  if (isErrorU) {
    toast.error(messageU);
  }

  showUser(user.id)
  
}, [isFetchSuccessU]);

const showUser = async(id) =>{
	try {
		setIsloading(true)
		const { data} = await getUser(id)
		console.log(data);
		if(data.success){
			setProfile(data.data)
			setReferal(data.referals)
			setAuthor(data.author)
		}
		setIsloading(false)
	} catch (error) {
		const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        toast.error(message)
	}
  }



  const referraList = () => {
    if (referals !== null && referals.length > 0) {
      return referals.map((item) => {
        return (
          <tr key={item.id}>
      <td>{dateFormat(item.dateRegistered, "dddd, mmmm dS, yyyy")}</td>
            <td>{item.first_name + " "+ item.last_name}</td>
      <td>{item.email}</td>
            <td>{item.phone_number}</td> 
          </tr>
        );
      });
    } else {
      <div className="alert alert-primary"> No item found</div>;
    }
  };

  return (
    <>
    <div className="inner-page-wrapper">
			
      {/* Sidebar */}
              <Sidebar />
    <div id="content-wrapper" className="content-wrapper d-flex flex-column">

      {/* Main Content  */}
      <div id="content">

         {/* Topbar */}

      <UserTopBar />

         {/* Begin Page Content  */}
        <div className="container-fluid">

          {/* Page Heading  */}
          <div className="d-sm-flex align-items-center justify-content-between mb-5">
            
          <div className="page-breadcrumbs">
            <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-center text-center">
              <li className="breadcrumb-item ">Dashboard</li>
              <li className="breadcrumb-item active" aria-current="page">
               My Referals
              </li>
            </ol>
            </nav>
                  </div>
          </div>
          <div className="col-lg-6 mb-2">
			<strong>Refered By: </strong> {author !== null ? author.first_name+" "+ author.last_name +"("+author.referal_code+")": "Not refered by anyone!"}
		</div>
          <div className="card shadow mb-4">
                <div className="card-header py-3 top">
                  <h6 className="m-0 font-weight-bold text-primary">
                  My Referral List
                  </h6>
                  <div>
                    {" "}
                    
                  </div>
                </div>

                {/* { isLoadingP ? <> Loading....</> :  */}
                <>
                
                <div className="card-body">
                  <div >
                    <div className="data-table">
                      <div className="row">
                        <div className="col-sm-12">
                          

	<p> Your referral count is <span className='ref-count'>
        {referals !== null ? referals.length : 0}
        
    </span></p>
{
  referals !== null && referals.length > 0 ?
  <table className='table table-stripped table-bordered dataTable'>
  <thead>
    <tr>
    <th>Date Registered</th>
      <th>Full Name</th>
      <th>Email</th>
      <th>Phone No</th>
    </tr>
  </thead>
  <tbody>
    {referraList()}
    
    
  </tbody>
</table>
:
<Empty title="No referal record" details="You have not refer anyone." icon={<ImFileEmpty className='empty-icon' />} />
}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </>
                {/* } */}
                
              </div>
        
        </div>
      </div>
    </div>
  </div>

    </>
  )
}

export default Referals